const ExpandIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M16.9997 9.50957C16.8123 9.31642 16.5589 9.20801 16.2947 9.20801C16.0305 9.20801 15.7771 9.31642 15.5897 9.50957L11.9997 13.1807L8.4597 9.50957C8.27234 9.31642 8.01889 9.20801 7.7547 9.20801C7.49052 9.20801 7.23707 9.31642 7.0497 9.50957C6.95598 9.60598 6.88158 9.72067 6.83081 9.84705C6.78004 9.97342 6.75391 10.109 6.75391 10.2459C6.75391 10.3828 6.78004 10.5183 6.83081 10.6447C6.88158 10.7711 6.95598 10.8858 7.0497 10.9822L11.2897 15.3792C11.3827 15.4764 11.4933 15.5535 11.6151 15.6062C11.737 15.6588 11.8677 15.686 11.9997 15.686C12.1317 15.686 12.2624 15.6588 12.3843 15.6062C12.5061 15.5535 12.6167 15.4764 12.7097 15.3792L16.9997 10.9822C17.0934 10.8858 17.1678 10.7711 17.2186 10.6447C17.2694 10.5183 17.2955 10.3828 17.2955 10.2459C17.2955 10.109 17.2694 9.97342 17.2186 9.84705C17.1678 9.72067 17.0934 9.60598 16.9997 9.50957Z"
      fill="#20332B"
    />
  </svg>
);

export default ExpandIcon;

import styled from 'styled-components';

import { colors } from '@/constants/colors';

export const MainTitle = styled.div`
  color: ${colors.green};
  font-family: Marcellus;
  font-size: 36px;
  line-height: normal;
  text-align: center;
  margin-bottom: 26px;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 8px;
  }
`;

export const SubTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

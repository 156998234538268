import { colors } from '@/constants/colors';

const Quote180Icon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ooui:quotes-ltr">
      <path
        id="Vector"
        d="M21 18L24 12H18C11.37 12 6 20.37 6 27V48H27V27H15C15 18 21 18 21 18ZM42 27C42 18 48 18 48 18L51 12H45C38.37 12 33 20.37 33 27V48H54V27H42Z"
        fill={colors.cucumber}
        fillOpacity="0.5"
      />
    </g>
  </svg>
);

export default Quote180Icon;

const CelebrationIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3885 3.12796C12.5002 3.28273 12.5587 3.46962 12.555 3.66049C12.5514 3.85136 12.4858 4.03587 12.3682 4.18624C11.6918 5.05155 10.2577 6.78421 8.83175 7.8364C7.07269 9.1364 5.47003 9.71937 4.65347 9.95702C4.29191 10.0626 3.90597 9.92249 3.68863 9.61577C3.15238 8.86218 2.29925 7.24733 2.63441 4.96218C3.10769 1.71218 6.83097 0.144054 9.2441 0.924054C11.6572 1.70405 12.3885 3.12796 12.3885 3.12796Z"
      fill="#FFC107"
    />
    <path
      d="M4.9766 9.34937C4.73895 9.4489 4.52363 9.17062 4.67598 8.96546C5.12285 8.36828 5.9902 7.38921 7.57254 6.11359C9.28895 4.73031 10.5057 4.24078 11.1699 4.06812C11.3994 4.00921 11.5761 4.26718 11.438 4.46015C10.9891 5.08984 10.0446 6.23953 8.49879 7.34249C6.99973 8.41296 5.72816 9.03249 4.9766 9.34937Z"
      fill="url(#paint0_radial_6033_9603)"
    />
    <path
      d="M6.64904 1.77549C6.74248 2.17768 7.25029 2.31784 7.74186 2.23455C8.27404 2.14518 8.94436 2.07002 8.88139 1.52971C8.81639 0.971117 7.9267 0.993461 7.59764 1.01174C7.11623 1.04018 6.56576 1.42002 6.64904 1.77549Z"
      fill="#FFEE58"
    />
    <path
      d="M17.1137 0.755242C19.5431 0.10118 23.11 0.938055 23.825 5.1468C24.0971 6.7454 23.4614 8.16118 23.0165 8.9168C22.7971 9.29056 22.3685 9.48962 21.944 9.4043C21.1153 9.23774 19.5715 8.77259 17.5037 7.48071C16.354 6.76368 14.79 5.36618 13.9043 4.54352C13.5042 4.1718 13.4656 3.54821 13.8251 3.13587C14.5279 2.32946 15.765 1.11884 17.1137 0.755242Z"
      fill="#FFC107"
    />
    <path
      d="M14.9124 4.57022C14.7845 4.44225 14.9063 4.22694 15.0831 4.27163C15.859 4.46663 17.291 4.90335 18.5626 5.69756C19.8666 6.51413 21.1849 7.75522 21.8532 8.41944C21.9852 8.54944 21.8552 8.76882 21.6765 8.716C20.8599 8.47835 19.3588 7.95022 17.874 6.94881C16.3648 5.93116 15.404 5.05975 14.9124 4.57022Z"
      fill="url(#paint1_radial_6033_9603)"
    />
    <path
      d="M16.9806 2.40457C16.1783 2.65644 15.9528 2.08972 16.3814 1.6591C16.6902 1.34832 17.9922 0.793785 19.1216 1.02941C19.867 1.18582 20.1006 2.26035 19.0505 2.24613C18.3355 2.23597 17.9637 2.09582 16.9806 2.40457Z"
      fill="#FFEE58"
    />
    <path
      d="M22.3469 24.3849C19.818 22.7944 18.124 19.6968 17.8152 16.0974C17.5369 12.8454 18.325 9.55269 18.9588 6.90801L20.2588 7.4991C19.6535 10.03 19.1782 12.9672 19.4341 15.9572C19.6982 19.0508 21.1119 21.6874 23.2122 23.0077L22.3469 24.3849ZM12.3044 23.8243L11.6341 22.3435C12.4283 21.9839 12.8874 20.9419 12.6152 20.1152C12.4832 19.711 12.1988 19.325 11.9002 18.9168C11.6565 18.5836 11.4025 18.2383 11.1954 17.8422C10.655 16.8063 10.5271 15.5672 10.848 14.4439C11.0105 13.8772 11.2685 13.3755 11.5204 12.89C11.8047 12.3416 12.0708 11.8257 12.1399 11.3057C12.2029 10.8243 12.0891 10.3144 11.825 9.90613C11.5854 9.53848 11.2157 9.2216 10.8236 8.88848C10.5271 8.63457 10.2183 8.37254 9.93598 8.06379C9.2677 7.33254 9.0077 6.46113 9.01177 5.48816L10.1432 5.05957C10.1432 5.05957 10.3483 6.16457 10.6246 6.60535C10.915 7.06645 11.3477 7.37926 11.758 7.72863C12.2211 8.12473 12.8204 8.45582 13.188 9.02457C13.6593 9.75176 13.8644 10.6618 13.7507 11.523C13.645 12.3233 13.2977 12.9936 12.9625 13.6416C12.7391 14.0743 12.5279 14.4825 12.41 14.8949C12.2029 15.6221 12.2841 16.4244 12.6355 17.0947C12.7858 17.3832 12.991 17.6635 13.2104 17.9621C13.5719 18.4557 13.9457 18.9635 14.159 19.6135C14.6932 21.2283 13.8604 23.1214 12.3044 23.8243Z"
      fill="#03A9F4"
    />
    <path
      d="M2.13656 25.047L1.17578 23.7368C7.33656 19.2234 7.99266 12.6137 7.55797 9.2459L8.78484 8.87012C9.26625 12.5934 8.96969 20.042 2.13656 25.047Z"
      fill="#F48FB1"
    />
    <path
      d="M19.28 14.9014L19.3877 14.9847C19.3755 14.3164 19.408 13.6441 19.4709 12.9778C18.9266 12.5248 18.4187 12.0394 18.0531 11.4706C18.043 11.4544 18.0348 11.4381 18.0247 11.4219C17.915 12.1531 17.8297 12.9006 17.7891 13.6542C18.2745 14.1194 18.7945 14.5256 19.28 14.9014Z"
      fill="#0076C6"
    />
    <path
      d="M23.347 19.3677L21.8724 18.6832C22.2481 17.8747 21.9333 16.8977 21.5149 16.2538C20.9705 15.4169 20.1133 14.7507 19.2825 14.1068C18.3908 13.4161 17.3813 12.6341 16.6866 11.5514C15.4089 9.56285 15.6344 7.08676 17.0745 5.21191L18.2547 5.87207C17.2188 7.22285 17.1355 9.23988 18.0536 10.6719C18.596 11.5189 19.4511 12.1791 20.2778 12.821C21.1716 13.5136 22.1831 14.2977 22.8778 15.3661C23.7147 16.656 23.8955 18.1896 23.347 19.3677ZM1.82798 18.8071C1.52329 17.0846 2.78267 16.1929 3.45907 15.7135C3.54032 15.6566 3.63986 15.5855 3.70892 15.5307C3.70282 15.3864 3.60532 15.2057 3.42251 14.8989C3.12189 14.3932 2.71157 13.6985 3.02642 12.8149C3.30064 12.0471 4.09689 11.7932 4.62501 11.4966C5.07595 11.2427 5.40298 10.9339 5.56548 10.418C5.75439 9.81676 5.62236 8.30551 5.62236 8.30551L6.61361 7.7266C6.61361 7.7266 6.92642 9.17082 6.92642 9.90613C6.92642 11.9902 5.77064 12.5224 5.28314 12.7966C4.94595 12.9855 4.62704 13.1643 4.55798 13.3593C4.5072 13.5014 4.57829 13.6619 4.82204 14.0722C5.11454 14.5638 5.51267 15.2402 5.25267 16.1035C5.12673 16.5239 4.77329 16.7758 4.39954 17.0399C3.73736 17.5091 3.31079 17.8666 3.42861 18.5247L1.82798 18.8071ZM15.2464 16.7088C14.5233 16.5361 14.0297 16.1055 13.5869 15.7399L14.6228 14.4866C14.6228 14.4866 14.9905 15.033 15.7969 15.2849L15.2464 16.7088ZM13.9424 7.5641L12.7419 6.85926L13.6113 5.4841L14.8138 6.18895L13.9424 7.5641ZM17.0583 23.0666L15.4394 22.0611L16.6886 20.6718L17.9378 21.858L17.0583 23.0666Z"
      fill="#F44336"
    />
    <path
      d="M22.1836 12.2215L23.1363 10.9053L24.55 11.9282L23.5971 13.2445L22.1836 12.2215Z"
      fill="#FB8C00"
    />
    <path
      d="M10.7427 11.5898L9.30859 10.5986L10.0541 9.60938L11.4861 10.6006L10.7427 11.5898Z"
      fill="#F44336"
    />
    <path
      d="M12.4766 13.3233C12.4766 13.3233 12.7528 13.3131 13.0961 13.3802C13.3439 12.8927 13.5795 12.3849 13.7014 11.8141C13.2789 11.7186 12.9397 11.7653 12.9397 11.7653L12.4766 13.3233Z"
      fill="#0076C6"
    />
    <path
      d="M14.4043 13.5138C13.8376 13.0304 12.891 13.0649 12.891 13.0649L13.3521 11.5069C13.3521 11.5069 14.4003 11.3607 15.1071 12.098L14.4043 13.5138ZM0.941406 19.9691L2.41548 19.8789L2.51441 21.5011L1.04053 21.5909L0.941406 19.9691ZM8.76761 19.6847L7.8312 18.3563C8.24558 18.0638 8.62542 17.7164 8.95448 17.3285L10.1935 18.3807C9.7745 18.8731 9.29546 19.3112 8.76761 19.6847Z"
      fill="#FB8C00"
    />
    <path
      d="M8.78172 23.4384C8.29422 23.0362 7.77828 22.6625 7.24609 22.3253L8.11547 20.9521C8.70453 21.3239 9.27531 21.7382 9.81563 22.1831L8.78172 23.4384Z"
      fill="#F44336"
    />
    <path
      d="M17.8438 16.2684C17.9006 16.9407 17.9798 17.4241 18.1302 18.0538L19.2006 17.1946L18.1586 15.8926L17.8438 16.2684Z"
      fill="#0076C6"
    />
    <path
      d="M16.1211 17.3046L17.7583 15.9922L18.7745 17.2599L17.1373 18.5725L16.1211 17.3046Z"
      fill="#FFC107"
    />
    <path
      d="M15.5619 25.4432C15.2775 24.9212 14.9586 24.4114 14.6133 23.9279L15.9356 22.9834C16.3209 23.5224 16.6723 24.0848 16.9878 24.6673L15.5619 25.4432Z"
      fill="#FB8C00"
    />
    <defs>
      <radialGradient
        id="paint0_radial_6033_9603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.05638 6.71606) scale(3.06759)"
      >
        <stop offset="0.376" stopColor="#AF5700" />
        <stop offset="1" stopColor="#8F4700" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_6033_9603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.382 6.49382) scale(4.31112)"
      >
        <stop offset="0.376" stopColor="#AF5700" />
        <stop offset="1" stopColor="#8F4700" />
      </radialGradient>
    </defs>
  </svg>
);

export default CelebrationIcon;

import { colors } from '@/constants/colors';

const BackIcon = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6H3.41002L6.71002 2.71C6.89832 2.5217 7.00411 2.2663 7.00411 2C7.00411 1.7337 6.89832 1.47831 6.71002 1.29C6.52172 1.1017 6.26632 0.995911 6.00002 0.995911C5.73372 0.995911 5.47832 1.1017 5.29002 1.29L0.290018 6.29C0.198978 6.38511 0.127613 6.49725 0.0800184 6.62C-0.0199996 6.86346 -0.0199996 7.13654 0.0800184 7.38C0.127613 7.50275 0.198978 7.6149 0.290018 7.71L5.29002 12.71C5.38298 12.8037 5.49358 12.8781 5.61544 12.9289C5.7373 12.9797 5.86801 13.0058 6.00002 13.0058C6.13203 13.0058 6.26274 12.9797 6.3846 12.9289C6.50645 12.8781 6.61706 12.8037 6.71002 12.71C6.80375 12.617 6.87814 12.5064 6.92891 12.3846C6.97968 12.2627 7.00582 12.132 7.00582 12C7.00582 11.868 6.97968 11.7373 6.92891 11.6154C6.87814 11.4936 6.80375 11.383 6.71002 11.29L3.41002 8H11C11.2652 8 11.5196 7.89465 11.7071 7.70711C11.8947 7.51957 12 7.26522 12 7C12 6.73479 11.8947 6.48043 11.7071 6.2929C11.5196 6.10536 11.2652 6 11 6Z"
      fill={colors.green}
    />
  </svg>
);

export default BackIcon;

const FireworksIcon = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.65633 5.81696C9.56948 5.90527 9.50357 6.01172 9.44851 6.12841L9.44231 6.1221L0.744776 26.0438L0.753306 26.0525C0.592011 26.3702 0.86187 27.0168 1.41477 27.5798C1.96767 28.142 2.60355 28.4164 2.91606 28.2523L2.92381 28.2602L22.5165 19.4159L22.5103 19.4088C22.6243 19.3536 22.729 19.2866 22.8166 19.1967C24.0279 17.9651 22.0637 13.9714 18.4306 10.2766C14.7961 6.58179 10.8684 4.58536 9.65633 5.81696Z"
      fill="#DD2E44"
    />
    <path
      d="M10.7218 9.375L0.963455 25.5436L0.744776 26.0442L0.753306 26.0529C0.592011 26.3707 0.86187 27.0172 1.41477 27.5802C1.59468 27.7631 1.78156 27.9019 1.96457 28.0194L13.8236 13.3174L10.7218 9.375Z"
      fill="#EA596E"
    />
    <path
      d="M18.4859 10.2159C22.1073 13.8997 24.118 17.8263 22.975 18.987C21.8328 20.1492 17.971 18.1055 14.3473 14.4233C10.7251 10.7395 8.71512 6.81128 9.85737 5.64985C11.0004 4.48842 14.8622 6.53216 18.4859 10.2159Z"
      fill="#A0041E"
    />
    <path
      d="M15.0553 10.6434C14.9009 10.7703 14.6993 10.8366 14.4861 10.8129C13.813 10.7388 13.2469 10.5007 12.8506 10.1246C12.4311 9.7264 12.2241 9.19181 12.2807 8.65643C12.3799 7.71656 13.3074 6.85397 14.8885 7.02743C15.5035 7.09445 15.778 6.89339 15.7873 6.7972C15.7981 6.70179 15.5725 6.44554 14.9575 6.37773C14.2844 6.30361 13.7184 6.06549 13.3213 5.68938C12.9018 5.2912 12.694 4.75661 12.7514 4.22124C12.8522 3.28137 13.7788 2.41877 15.3585 2.59303C15.8067 2.64191 16.0432 2.54808 16.1432 2.48737C16.2231 2.4377 16.2549 2.39039 16.258 2.36279C16.2673 2.26738 16.0447 2.01113 15.4282 1.94332C15.0025 1.89601 14.6939 1.50808 14.7412 1.07442C14.7869 0.64154 15.1677 0.328514 15.595 0.375823C17.1746 0.548499 17.9004 1.59166 17.8004 2.53231C17.6996 3.47376 16.7729 4.33478 15.1917 4.1621C14.7435 4.11243 14.5093 4.20704 14.4085 4.26776C14.3286 4.31664 14.2961 4.36474 14.293 4.39155C14.2829 4.48774 14.507 4.74321 15.1235 4.81102C16.7031 4.98448 17.4289 6.02685 17.3289 6.96751C17.2289 7.90738 16.3022 8.76997 14.7218 8.59572C14.2736 8.54683 14.0379 8.64145 13.937 8.70138C13.8564 8.75184 13.8254 8.79915 13.8223 8.82595C13.8122 8.92136 14.0363 9.17762 14.652 9.24543C15.077 9.29273 15.3864 9.68145 15.3391 10.1143C15.3174 10.3304 15.2096 10.5172 15.0553 10.6434Z"
      fill="#AA8DD8"
    />
    <path
      d="M24.4178 17.9341C25.9478 17.4949 27.0032 18.1888 27.2544 19.0995C27.5057 20.0094 26.9613 21.1614 25.4321 21.599C24.835 21.7693 24.6559 22.0594 24.6799 22.1517C24.7063 22.2447 25.0095 22.3977 25.605 22.2266C27.1342 21.789 28.1896 22.4828 28.4409 23.3927C28.6937 24.3034 28.1478 25.4538 26.6178 25.8922C26.0215 26.0625 25.8416 26.3535 25.8679 26.4457C25.8935 26.538 26.1959 26.691 26.7923 26.5206C27.2033 26.4032 27.6329 26.6468 27.7484 27.0655C27.8632 27.485 27.6236 27.9202 27.211 28.0385C25.6826 28.4761 24.6264 27.7838 24.3736 26.8723C24.1224 25.9624 24.6675 24.812 26.1983 24.3736C26.7954 24.2025 26.9745 23.9131 26.9481 23.8201C26.9233 23.7279 26.6209 23.5741 26.0253 23.7444C24.4946 24.1828 23.44 23.4905 23.1879 22.5782C22.9359 21.6683 23.4811 20.518 25.011 20.0788C25.6066 19.9092 25.7857 19.6175 25.7609 19.526C25.7345 19.433 25.4329 19.28 24.8366 19.4504C24.424 19.5686 23.9967 19.3242 23.8804 18.9055C23.7649 18.4876 24.0053 18.0524 24.4178 17.9341Z"
      fill="#77B255"
    />
    <path
      d="M18.4773 15.8101C18.2493 15.8101 18.0244 15.7084 17.8709 15.5144C17.6033 15.1738 17.6584 14.6778 17.9918 14.4058C18.1609 14.2678 22.1933 11.0477 27.8921 11.8764C28.3163 11.9379 28.611 12.3368 28.5505 12.7681C28.49 13.1986 28.1007 13.5014 27.6727 13.4368C22.6376 12.7098 18.9976 15.6082 18.9619 15.6374C18.8177 15.7541 18.6471 15.8101 18.4773 15.8101Z"
      fill="#AA8DD8"
    />
    <path
      d="M5.10157 12.5276C5.02791 12.5276 4.95269 12.5165 4.87824 12.4945C4.46803 12.3691 4.23539 11.9299 4.35869 11.5128C5.23728 8.53788 6.03367 3.79045 5.05505 2.55254C4.94571 2.41219 4.78054 2.2742 4.40211 2.30338C3.67473 2.36015 3.74375 3.92055 3.74452 3.93632C3.77709 4.37077 3.45605 4.74924 3.02955 4.78157C2.59607 4.80837 2.23006 4.48825 2.19826 4.0538C2.11839 2.96649 2.45106 0.872286 4.28579 0.731148C5.10468 0.66807 5.78475 0.957442 6.26476 1.56457C8.10337 3.89137 6.23684 10.6368 5.84446 11.9662C5.74365 12.3076 5.43502 12.5276 5.10157 12.5276Z"
      fill="#77B255"
    />
    <path
      d="M20.4151 8.58614C21.0575 8.58614 21.5783 8.05662 21.5783 7.40342C21.5783 6.75022 21.0575 6.2207 20.4151 6.2207C19.7727 6.2207 19.252 6.75022 19.252 7.40342C19.252 8.05662 19.7727 8.58614 20.4151 8.58614Z"
      fill="#5C913B"
    />
    <path
      d="M2.19154 15.6832C3.04809 15.6832 3.74246 14.9772 3.74246 14.1063C3.74246 13.2353 3.04809 12.5293 2.19154 12.5293C1.33499 12.5293 0.640625 13.2353 0.640625 14.1063C0.640625 14.9772 1.33499 15.6832 2.19154 15.6832Z"
      fill="#9266CC"
    />
    <path
      d="M25.8429 16.4729C26.4853 16.4729 27.0061 15.9433 27.0061 15.2901C27.0061 14.6369 26.4853 14.1074 25.8429 14.1074C25.2005 14.1074 24.6797 14.6369 24.6797 15.2901C24.6797 15.9433 25.2005 16.4729 25.8429 16.4729Z"
      fill="#5C913B"
    />
    <path
      d="M18.8644 25.9338C19.5068 25.9338 20.0275 25.4043 20.0275 24.7511C20.0275 24.0979 19.5068 23.5684 18.8644 23.5684C18.2219 23.5684 17.7012 24.0979 17.7012 24.7511C17.7012 25.4043 18.2219 25.9338 18.8644 25.9338Z"
      fill="#5C913B"
    />
    <path
      d="M22.3517 4.64415C23.2082 4.64415 23.9026 3.93812 23.9026 3.06719C23.9026 2.19626 23.2082 1.49023 22.3517 1.49023C21.4951 1.49023 20.8008 2.19626 20.8008 3.06719C20.8008 3.93812 21.4951 4.64415 22.3517 4.64415Z"
      fill="#FFCC4D"
    />
    <path
      d="M25.8429 7.79903C26.4853 7.79903 27.0061 7.26951 27.0061 6.61631C27.0061 5.96311 26.4853 5.43359 25.8429 5.43359C25.2005 5.43359 24.6797 5.96311 24.6797 6.61631C24.6797 7.26951 25.2005 7.79903 25.8429 7.79903Z"
      fill="#FFCC4D"
    />
    <path
      d="M23.5167 10.9533C24.1591 10.9533 24.6799 10.4238 24.6799 9.77061C24.6799 9.11741 24.1591 8.58789 23.5167 8.58789C22.8743 8.58789 22.3535 9.11741 22.3535 9.77061C22.3535 10.4238 22.8743 10.9533 23.5167 10.9533Z"
      fill="#FFCC4D"
    />
    <path
      d="M6.45616 19.6252C7.09857 19.6252 7.61934 19.0957 7.61934 18.4425C7.61934 17.7893 7.09857 17.2598 6.45616 17.2598C5.81375 17.2598 5.29297 17.7893 5.29297 18.4425C5.29297 19.0957 5.81375 19.6252 6.45616 19.6252Z"
      fill="#FFCC4D"
    />
  </svg>
);

export default FireworksIcon;

const CollapseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M7.0003 15.3781C7.18766 15.5713 7.44111 15.6797 7.7053 15.6797C7.96948 15.6797 8.22293 15.5713 8.4103 15.3781L12.0003 11.707L15.5403 15.3781C15.7277 15.5713 15.9811 15.6797 16.2453 15.6797C16.5095 15.6797 16.7629 15.5713 16.9503 15.3781C17.044 15.2817 17.1184 15.167 17.1692 15.0406C17.22 14.9143 17.2461 14.7787 17.2461 14.6418C17.2461 14.5049 17.22 14.3694 17.1692 14.243C17.1184 14.1166 17.044 14.0019 16.9503 13.9055L12.7103 9.50849C12.6173 9.41129 12.5067 9.33415 12.3849 9.2815C12.263 9.22885 12.1323 9.20174 12.0003 9.20174C11.8683 9.20174 11.7376 9.22885 11.6157 9.2815C11.4939 9.33415 11.3833 9.41129 11.2903 9.50849L7.0003 13.9055C6.90657 14.0019 6.83217 14.1166 6.7814 14.243C6.73064 14.3694 6.7045 14.5049 6.7045 14.6418C6.7045 14.7787 6.73064 14.9143 6.7814 15.0406C6.83217 15.167 6.90657 15.2817 7.0003 15.3781Z"
      fill="#20332B"
    />
  </svg>
);

export default CollapseIcon;

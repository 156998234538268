import { colors } from '@/constants/colors';

const NextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M13.7545 10.4082C13.4752 10.6868 13.3184 11.0636 13.3184 11.4563C13.3184 11.8491 13.4752 12.2259 13.7545 12.5044L19.0645 17.8417L13.7545 23.1046C13.4752 23.3832 13.3184 23.76 13.3184 24.1527C13.3184 24.5455 13.4752 24.9223 13.7545 25.2009C13.894 25.3402 14.0599 25.4508 14.2427 25.5263C14.4255 25.6018 14.6215 25.6406 14.8195 25.6406C15.0176 25.6406 15.2136 25.6018 15.3964 25.5263C15.5792 25.4508 15.7451 25.3402 15.8845 25.2009L22.2445 18.8973C22.3851 18.759 22.4967 18.5946 22.5729 18.4134C22.649 18.2323 22.6882 18.038 22.6882 17.8417C22.6882 17.6454 22.649 17.4511 22.5729 17.2699C22.4967 17.0888 22.3851 16.9244 22.2445 16.7861L15.8845 10.4082C15.7451 10.2689 15.5792 10.1583 15.3964 10.0828C15.2136 10.0073 15.0176 9.96844 14.8195 9.96844C14.6215 9.96844 14.4255 10.0073 14.2427 10.0828C14.0599 10.1583 13.894 10.2689 13.7545 10.4082Z"
      fill={colors.white}
    />
  </svg>
);

export default NextIcon;

import Image from 'next/image';
import styled from 'styled-components';

import ArrowImage from '@/assets/images/arrowIcon.svg';

interface ArrowProps {
  orientation?: 'down' | 'up' | 'left' | 'right';
}

const Arrow = styled(Image)<ArrowProps>`
  ${({ orientation }) => orientation === 'down' && 'transform: rotate(0deg)'};
  ${({ orientation }) => orientation === 'up' && 'transform: rotate(180deg)'};
  ${({ orientation }) => orientation === 'left' && 'transform: rotate(90deg)'};
  ${({ orientation }) =>
    orientation === 'right' && 'transform: rotate(-90deg)'};

  width: 16px;
  height: 24px;
  position: relative;
  top: 8px;
  left: 6px;
`;

const ArrowIcon = ({ orientation = 'down' }: ArrowProps) => (
  <Arrow
    data-testid="arrow-icon"
    src={ArrowImage}
    orientation={orientation}
    alt=""
  />
);

export default ArrowIcon;

const UploadFileSuccessIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0.333313C10.0834 0.333313 0.333374 10.0833 0.333374 22C0.333374 33.9166 10.0834 43.6666 22 43.6666C33.9167 43.6666 43.6667 33.9166 43.6667 22C43.6667 10.0833 33.9167 0.333313 22 0.333313ZM31.1 18.3166L20.7 28.7166C19.8334 29.5833 18.5334 29.5833 17.6667 28.7166L12.9 23.95C12.0334 23.0833 12.0334 21.7833 12.9 20.9166C13.7667 20.05 15.0667 20.05 15.9334 20.9166L19.1834 24.1666L28.0667 15.2833C28.9334 14.4166 30.2334 14.4166 31.1 15.2833C31.9667 16.15 31.9667 17.45 31.1 18.3166Z"
      fill="#9461BC"
    />
  </svg>
);

export default UploadFileSuccessIcon;

const RadioCustomIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <circle cx="10" cy="10" r="9" stroke="#41556B" strokeWidth="2" />
    <circle cx="10" cy="10" r="5.5" fill="#9461BC" stroke="#9461BC" />
  </svg>
);

export default RadioCustomIcon;

import { colors } from '@/constants/colors';

const ForwardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6.99998 12.9998L14.59 12.9998L11.29 16.2898C11.1017 16.4781 10.9959 16.7335 10.9959 16.9998C10.9959 17.2661 11.1017 17.5215 11.29 17.7098C11.4783 17.8981 11.7337 18.0039 12 18.0039C12.2663 18.0039 12.5217 17.8981 12.71 17.7098L17.71 12.7098C17.801 12.6147 17.8724 12.5026 17.92 12.3798C18.02 12.1364 18.02 11.8633 17.92 11.6198C17.8724 11.4971 17.801 11.3849 17.71 11.2898L12.71 6.28982C12.617 6.19609 12.5064 6.12169 12.3846 6.07092C12.2627 6.02015 12.132 5.99402 12 5.99402C11.868 5.99402 11.7373 6.02015 11.6154 6.07092C11.4935 6.12169 11.3829 6.19609 11.29 6.28982C11.1963 6.38278 11.1219 6.49338 11.0711 6.61524C11.0203 6.7371 10.9942 6.8678 10.9942 6.99981C10.9942 7.13183 11.0203 7.26253 11.0711 7.38439C11.1219 7.50625 11.1963 7.61685 11.29 7.70981L14.59 10.9998L6.99998 10.9998C6.73477 10.9998 6.48041 11.1052 6.29287 11.2927C6.10534 11.4802 5.99998 11.7346 5.99998 11.9998C5.99998 12.265 6.10534 12.5194 6.29287 12.7069C6.48041 12.8945 6.73477 12.9998 6.99998 12.9998Z"
      fill={colors.green}
    />
  </svg>
);

export default ForwardIcon;

import { colors } from '@/constants/colors';

const PreviousIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M22.245 25.2739C22.5243 24.9954 22.6812 24.6186 22.6812 24.2258C22.6812 23.833 22.5243 23.4562 22.245 23.1777L16.935 17.8404L22.245 12.5775C22.5243 12.299 22.6812 11.9222 22.6812 11.5294C22.6812 11.1366 22.5243 10.7598 22.245 10.4813C22.1055 10.3419 21.9396 10.2313 21.7568 10.1558C21.574 10.0804 21.378 10.0415 21.18 10.0415C20.9819 10.0415 20.7859 10.0804 20.6031 10.1558C20.4203 10.2313 20.2544 10.3419 20.115 10.4813L13.755 16.7849C13.6144 16.9231 13.5028 17.0875 13.4266 17.2687C13.3505 17.4498 13.3113 17.6442 13.3113 17.8404C13.3113 18.0367 13.3505 18.231 13.4266 18.4122C13.5028 18.5933 13.6144 18.7578 13.755 18.896L20.115 25.2739C20.2544 25.4133 20.4203 25.5239 20.6031 25.5994C20.7859 25.6748 20.9819 25.7137 21.18 25.7137C21.378 25.7137 21.574 25.6748 21.7568 25.5994C21.9396 25.5239 22.1055 25.4133 22.245 25.2739Z"
      fill={colors.white}
    />
  </svg>
);

export default PreviousIcon;

const BackToTopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <g filter="url(#filter0_d_2065_29605)">
      <circle cx="32" cy="28" r="28" fill="#3F6655" />
    </g>
    <path
      d="M41.7075 29.2925C41.8004 29.3854 41.8741 29.4957 41.9244 29.6171C41.9747 29.7385 42.0006 29.8686 42.0006 30C42.0006 30.1314 41.9747 30.2615 41.9244 30.3829C41.8741 30.5043 41.8004 30.6146 41.7075 30.7075C41.6146 30.8004 41.5043 30.8741 41.3829 30.9244C41.2615 30.9747 41.1314 31.0006 41 31.0006C40.8686 31.0006 40.7385 30.9747 40.6171 30.9244C40.4957 30.8741 40.3854 30.8004 40.2925 30.7075L33 23.4137V40C33 40.2652 32.8946 40.5196 32.7071 40.7071C32.5196 40.8946 32.2652 41 32 41C31.7348 41 31.4804 40.8946 31.2929 40.7071C31.1054 40.5196 31 40.2652 31 40V23.4137L23.7075 30.7075C23.6146 30.8004 23.5043 30.8741 23.3829 30.9244C23.2615 30.9747 23.1314 31.0006 23 31.0006C22.8686 31.0006 22.7385 30.9747 22.6171 30.9244C22.4957 30.8741 22.3854 30.8004 22.2925 30.7075C22.1996 30.6146 22.1259 30.5043 22.0756 30.3829C22.0253 30.2615 21.9994 30.1314 21.9994 30C21.9994 29.8686 22.0253 29.7385 22.0756 29.6171C22.1259 29.4957 22.1996 29.3854 22.2925 29.2925L31.2925 20.2925C31.3854 20.1995 31.4957 20.1258 31.6171 20.0754C31.7385 20.0251 31.8686 19.9992 32 19.9992C32.1314 19.9992 32.2615 20.0251 32.3829 20.0754C32.5043 20.1258 32.6146 20.1995 32.7075 20.2925L41.7075 29.2925ZM43 16H21C20.7348 16 20.4804 16.1054 20.2929 16.2929C20.1054 16.4804 20 16.7348 20 17C20 17.2652 20.1054 17.5196 20.2929 17.7071C20.4804 17.8946 20.7348 18 21 18H43C43.2652 18 43.5196 17.8946 43.7071 17.7071C43.8946 17.5196 44 17.2652 44 17C44 16.7348 43.8946 16.4804 43.7071 16.2929C43.5196 16.1054 43.2652 16 43 16Z"
      fill="#F8F7E5"
    />
    <defs>
      <filter
        id="filter0_d_2065_29605"
        x="0"
        y="0"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2065_29605"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2065_29605"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default BackToTopIcon;

import AetnaIcon from './AetnaIcon';
import ArrowIcon from './ArrowIcon';
import BackIcon from './BackIcon';
import BackToTopIcon from './BackToTopIcon';
import BadgeIcon from './BadgeIcon';
import BlueCrossIcon from './BlueCrossIcon';
import CelebrationIcon from './CelebrationIcon';
import CheckIcon from './CheckIcon';
import CheckWaveIcon from './CheckWaveIcon';
import CignaIcon from './CignaIcon';
import CloseIcon from './CloseIcon';
import CollapseIcon from './CollapseIcon';
import DuplicateProfileIcon from './DuplicateProfileIcon';
import EnvelopeIcon from './EnvelopeIcon';
import ExpandIcon from './ExpandIcon';
import FilterIcon from './FilterIcon';
import FireworksIcon from './FireworksIcon';
import ForwardIcon from './ForwardIcon';
import HappyCustomerIcon from './HappyCustomerIcon';
import ImportIcon from './ImportIcon';
import LockIcon from './LockIcon';
import MatchWithRDPicture from './MatchWithRDPicture';
import NextIcon from './NextIcon';
import OpenBookIcon from './OpenBookIcon';
import PreviousIcon from './PreviousIcon';
import Quote180Icon from './Quote180Icon';
import QuoteIcon from './QuoteIcon';
import RadioCustomIcon from './RadioCustomIcon';
import RotateStarIcon from './RotateStarIcon';
import SelectIcon from './SelectIcon';
import StarIcon from './StarIcon';
import ThrashIcon from './ThrashIcon';
import UHCIcon from './UHCIcon';
import UploadFileIcon from './UploadFileIcon';
import UploadFileSuccessIcon from './UploadFileSuccessIcon';
import WaveIcon from './WaveIcon';

export {
  AetnaIcon,
  ArrowIcon,
  BackIcon,
  BackToTopIcon,
  BadgeIcon,
  BlueCrossIcon,
  CelebrationIcon,
  CheckIcon,
  CheckWaveIcon,
  CignaIcon,
  CloseIcon,
  CollapseIcon,
  DuplicateProfileIcon,
  EnvelopeIcon,
  ExpandIcon,
  FilterIcon,
  FireworksIcon,
  ForwardIcon,
  HappyCustomerIcon,
  ImportIcon,
  LockIcon,
  MatchWithRDPicture,
  NextIcon,
  OpenBookIcon,
  PreviousIcon,
  Quote180Icon,
  QuoteIcon,
  RadioCustomIcon,
  RotateStarIcon,
  SelectIcon,
  StarIcon,
  ThrashIcon,
  UHCIcon,
  UploadFileIcon,
  UploadFileSuccessIcon,
  WaveIcon,
};

const DuplicateProfileIcon = () => (
  <svg
    width="284"
    height="228"
    viewBox="0 0 284 228"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M284 15.9492H135.391V114.671H284V15.9492Z" fill="#635A4C" />
    <path
      d="M273.912 24.2559H144.269V104.711H273.912V24.2559Z"
      fill="#D0DDEB"
    />
    <path
      d="M273.909 45.6413V30.4766C267.986 30.4766 265.888 35.0377 265.888 35.0377C258.363 35.0377 256.761 39.7463 256.761 39.7463C253.44 39.7463 251.339 40.4501 250.011 41.3691C248.119 42.6784 249.042 45.6413 251.342 45.6413H273.912H273.909Z"
      fill="#F8F7E5"
    />
    <path
      d="M251.008 76.5426L247.779 73.7119C248.455 72.8298 249.473 72.2182 251.008 72.0123C251.008 68.9295 257.829 68.9295 257.829 72.0123C261.526 71.2039 262.959 74.0254 262.959 74.0254C269.097 71.7233 272.415 76.5426 270.444 76.5426H251.008Z"
      fill="#F8F7E5"
    />
    <path
      d="M196.135 86.0154L201.858 79.601C200.659 77.6032 198.851 76.217 196.135 75.7529C196.135 68.7699 184.046 68.7699 184.046 75.7529C177.496 73.9211 174.952 80.3109 174.952 80.3109C164.072 75.0952 158.189 86.0154 161.682 86.0154H196.138H196.135Z"
      fill="#F8F7E5"
    />
    <path
      d="M159.779 104.712L185.163 84.8385C185.572 84.5188 186.079 84.3436 186.599 84.3436H188.035C188.576 84.3436 189.099 84.1561 189.517 83.8119L201.465 73.9582C201.886 73.6109 202.418 73.4203 202.966 73.4203C203.605 73.4203 204.214 73.1621 204.66 72.7042L210.577 66.594C211.026 66.1299 211.644 65.8687 212.287 65.8687H213.462C214.037 65.8687 214.593 65.6597 215.027 65.2816L218.803 61.993C219.314 61.5473 219.969 61.3045 220.646 61.3045H227.362C227.974 61.3045 228.583 61.2154 229.167 61.0371L232.529 60.0228C233.094 59.8507 233.685 59.7646 234.278 59.7646H237.757C238.399 59.7646 239.002 60.0689 239.387 60.5853L241.542 63.4959C241.896 63.9723 242.373 64.3442 242.923 64.5686L243.787 64.922C244.402 65.174 244.925 65.6074 245.285 66.1637L250.476 74.1918C250.848 74.7665 251.488 75.1138 252.174 75.1138C252.835 75.1138 253.453 75.4365 253.828 75.9775L258.906 83.2402C259.321 83.8334 259.841 84.3436 260.44 84.7493L262.036 85.8251C262.756 86.3107 263.362 86.953 263.805 87.6999L273.916 104.709H159.785L159.779 104.712Z"
      fill="#D9C092"
    />
    <path
      d="M10.6744 177.963C-3.55815 163.738 -3.55815 140.675 10.6744 126.45C24.907 112.226 47.9843 112.226 62.2168 126.45C76.4494 140.675 76.4494 163.738 62.2168 177.963H10.6744Z"
      fill="#B6CCAE"
    />
    <path
      d="M9.12793 177.963L16.6502 228H56.2419L63.7641 177.963H9.12793Z"
      fill="#D0DDEB"
    />
    <path
      d="M138.248 227.355H268.93C276.231 227.355 281.828 220.91 280.847 213.678C277.953 192.36 261.891 185.435 228.69 197.232H165.956C141.095 187 129.243 194.287 126.337 213.623C125.248 220.861 130.922 227.355 138.245 227.355H138.248Z"
      fill="#5E69BC"
    />
    <path
      d="M228.706 192.351C221.374 192.351 211.669 189.677 200.253 184.812C175.325 174.193 150.439 182.39 142.425 206.262C168.55 216.396 228.706 212.292 228.706 212.292V192.348V192.351Z"
      fill="#F2DA91"
    />
    <path
      d="M136.121 204.092C157.291 216.211 228.266 214.416 260.742 206.431"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M193.64 200.077C190.239 214.414 210.752 217.497 236.154 211.123L245.294 209.435C239.447 180.351 227.672 189.879 216.613 193.767C204.696 197.953 193.644 200.077 193.644 200.077H193.64Z"
      fill="#F2DA91"
    />
    <path
      d="M193.64 200.077C192.576 204.558 193.849 207.939 196.878 210.278"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M216.61 193.765C211.327 195.621 206.212 197.072 202.159 198.111"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M241.102 195.833C240.014 192.987 235.85 193.598 235.61 196.635C234.558 209.894 238.116 220.544 247.115 220.544C255.28 220.544 254.351 211.827 247.115 205.339C244.347 202.859 242.425 199.293 241.102 195.833Z"
      fill="#F2DA91"
    />
    <path
      d="M237.218 194.274C236.369 194.668 235.702 195.476 235.61 196.638C235.265 200.975 235.413 205.032 236.086 208.529"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.8333 3.73096C42.8337 3.73096 43.6447 2.92046 43.6447 1.92066C43.6447 0.920853 42.8337 0.110352 41.8333 0.110352C40.8329 0.110352 40.022 0.920853 40.022 1.92066C40.022 2.92046 40.8329 3.73096 41.8333 3.73096Z"
      fill="#F2DA91"
    />
    <path
      d="M119.432 51.6265C120.433 51.6265 121.244 50.816 121.244 49.8162C121.244 48.8164 120.433 48.0059 119.432 48.0059C118.432 48.0059 117.621 48.8164 117.621 49.8162C117.621 50.816 118.432 51.6265 119.432 51.6265Z"
      fill="#F2DA91"
    />
    <path
      d="M47.3907 35.2873C48.5624 28.1229 51.5916 23.9306 56.8781 23.3466C50.6905 22.7811 48.4978 16.0839 47.3907 7.71777C46.2836 16.087 44.0878 22.7842 37.9033 23.3466C43.1898 23.9306 46.2221 28.1229 47.3907 35.2873Z"
      fill="#F2DA91"
    />
    <path
      d="M63.4899 15.3215C64.1418 11.3413 65.824 9.01157 68.761 8.68578C65.3227 8.37228 64.1049 4.65024 63.4899 0C62.8748 4.65024 61.6539 8.3692 58.2188 8.68578C61.1557 9.01157 62.841 11.3413 63.4899 15.3215Z"
      fill="#F2DA91"
    />
    <path
      d="M124.707 43.7572C125.23 40.5515 126.586 38.6736 128.954 38.4123C126.187 38.1603 125.203 35.1636 124.707 31.417C124.212 35.1605 123.228 38.1572 120.46 38.4123C122.825 38.6736 124.182 40.5515 124.707 43.7572Z"
      fill="#F2DA91"
    />
    <path
      d="M226.205 41.7607L229.594 54.2914C229.634 54.4389 229.784 54.525 229.932 54.4851C230.079 54.4451 230.166 54.2945 230.126 54.147L226.737 41.6162L226.205 41.7607Z"
      fill="#A1BBD7"
    />
    <path
      d="M206.889 47.5358C206.978 47.8647 207.252 48.1136 207.587 48.1751C210.991 48.8113 214.509 47.7725 217.022 45.3567C219.673 42.8087 223.44 41.7914 227.017 42.6551L224.203 32.2543C224.114 31.9254 223.84 31.6764 223.505 31.615C220.1 30.9788 216.582 32.0176 214.07 34.4334C211.606 36.8031 208.174 37.8481 204.831 37.2887C204.481 37.2303 204.189 37.5469 204.281 37.8911L206.889 47.5358Z"
      fill="#F8F7E5"
    />
    <path
      d="M144.269 43.3278C146.32 35.7332 157.179 35.7332 159.781 43.3278C164.843 40.1375 170.852 43.835 170.074 49.7699C173.817 49.4718 176.166 50.9287 177.03 54.205C187.004 51.3774 193.373 61.9933 187.185 61.9933H144.272V43.3278H144.269Z"
      fill="#F8F7E5"
    />
    <path
      d="M223.452 197.028C222.598 199.807 225.768 201.783 227.921 199.629L228.96 200.99"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M196.157 206.741C199.472 206.741 202.16 204.682 202.16 202.143C202.16 199.604 199.472 197.545 196.157 197.545C192.841 197.545 190.154 199.604 190.154 202.143C190.154 204.682 192.841 206.741 196.157 206.741Z"
      fill="black"
    />
    <path
      d="M168.927 193.998C181.779 197.305 183.984 204.319 182.843 208.705"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M254.237 101.725C254.067 101.725 253.905 101.633 253.825 101.47L250.82 95.4614C250.509 94.8436 249.996 94.3365 249.371 94.0383L247.286 93.0394C246.496 92.6614 245.838 92.0221 245.435 91.2445L236.489 73.9252C236.372 73.6978 236.461 73.4211 236.686 73.3044C236.913 73.1876 237.19 73.2767 237.307 73.5011L246.253 90.8204C246.564 91.4228 247.071 91.9145 247.683 92.2096L249.768 93.2085C250.577 93.5957 251.244 94.2504 251.644 95.0526L254.649 101.061C254.763 101.289 254.67 101.565 254.443 101.679C254.375 101.713 254.307 101.728 254.237 101.728V101.725Z"
      fill="#F8F7E5"
    />
    <path
      d="M254.237 101.725C254.067 101.725 253.905 101.633 253.825 101.47L250.82 95.4614C250.509 94.8436 249.996 94.3365 249.371 94.0383L247.286 93.0394C246.496 92.6614 245.838 92.0221 245.435 91.2445L236.489 73.9252C236.372 73.6978 236.461 73.4211 236.686 73.3044C236.913 73.1876 237.19 73.2767 237.307 73.5011L246.253 90.8204C246.564 91.4228 247.071 91.9145 247.683 92.2096L249.768 93.2085C250.577 93.5957 251.244 94.2504 251.644 95.0526L254.649 101.061C254.763 101.289 254.67 101.565 254.443 101.679C254.375 101.713 254.307 101.728 254.237 101.728V101.725Z"
      fill="#F8F7E5"
    />
    <path
      d="M263.064 101.725C262.91 101.725 262.76 101.648 262.674 101.507L259.223 95.9717C259.088 95.7565 259.156 95.4707 259.371 95.3354C259.586 95.2002 259.872 95.2678 260.007 95.483L263.458 101.018C263.593 101.234 263.526 101.519 263.31 101.655C263.233 101.701 263.15 101.725 263.067 101.725H263.064Z"
      fill="#F8F7E5"
    />
    <path
      d="M263.064 101.725C262.91 101.725 262.76 101.648 262.674 101.507L259.223 95.9717C259.088 95.7565 259.156 95.4707 259.371 95.3354C259.586 95.2002 259.872 95.2678 260.007 95.483L263.458 101.018C263.593 101.234 263.526 101.519 263.31 101.655C263.233 101.701 263.15 101.725 263.067 101.725H263.064Z"
      fill="#F8F7E5"
    />
    <path
      d="M236 101.724C235.769 101.724 235.569 101.549 235.542 101.315L235.366 99.7263C235.289 99.044 235.022 98.3986 234.591 97.8607L233.819 96.8987C233.306 96.2594 232.974 95.4879 232.863 94.6765L232.153 89.5099C232.119 89.2579 232.294 89.0243 232.546 88.9905C232.798 88.9567 233.032 89.1319 233.066 89.3839L233.776 94.5505C233.866 95.199 234.13 95.8107 234.539 96.3239L235.311 97.2859C235.849 97.9591 236.187 98.7674 236.283 99.6249L236.458 101.214C236.486 101.466 236.304 101.693 236.049 101.724C236.031 101.724 236.015 101.724 235.997 101.724H236Z"
      fill="#F8F7E5"
    />
    <path
      d="M236 101.724C235.769 101.724 235.569 101.549 235.542 101.315L235.366 99.7263C235.289 99.044 235.022 98.3986 234.591 97.8607L233.819 96.8987C233.306 96.2594 232.974 95.4879 232.863 94.6765L232.153 89.5099C232.119 89.2579 232.294 89.0243 232.546 88.9905C232.798 88.9567 233.032 89.1319 233.066 89.3839L233.776 94.5505C233.866 95.199 234.13 95.8107 234.539 96.3239L235.311 97.2859C235.849 97.9591 236.187 98.7674 236.283 99.6249L236.458 101.214C236.486 101.466 236.304 101.693 236.049 101.724C236.031 101.724 236.015 101.724 235.997 101.724H236Z"
      fill="#F8F7E5"
    />
    <path
      d="M219.2 98.9562C219.167 98.9562 219.133 98.9532 219.099 98.9439C218.85 98.8886 218.696 98.6397 218.752 98.3907L219.56 94.8408C219.686 94.2814 219.649 93.7036 219.456 93.1657L219.154 92.3359C218.868 91.5521 218.841 90.7161 219.074 89.9139L220.827 83.9052C220.898 83.6593 221.156 83.521 221.399 83.5917C221.642 83.6624 221.784 83.9206 221.713 84.1634L219.96 90.1721C219.782 90.7837 219.803 91.42 220.022 92.0193L220.323 92.8491C220.581 93.553 220.627 94.3121 220.461 95.0436L219.653 98.5936C219.603 98.8087 219.413 98.9532 219.204 98.9532L219.2 98.9562Z"
      fill="#F8F7E5"
    />
    <path
      d="M219.2 98.9562C219.167 98.9562 219.133 98.9532 219.099 98.9439C218.85 98.8886 218.696 98.6397 218.752 98.3907L219.56 94.8408C219.686 94.2814 219.649 93.7036 219.456 93.1657L219.154 92.3359C218.868 91.5521 218.841 90.7161 219.074 89.9139L220.827 83.9052C220.898 83.6593 221.156 83.521 221.399 83.5917C221.642 83.6624 221.784 83.9206 221.713 84.1634L219.96 90.1721C219.782 90.7837 219.803 91.42 220.022 92.0193L220.323 92.8491C220.581 93.553 220.627 94.3121 220.461 95.0436L219.653 98.5936C219.603 98.8087 219.413 98.9532 219.204 98.9532L219.2 98.9562Z"
      fill="#F8F7E5"
    />
    <path
      d="M188.996 98.9539C188.883 98.9539 188.772 98.9139 188.683 98.831C188.495 98.6588 188.486 98.3668 188.658 98.1794L197.029 89.1739C197.555 88.6084 198.216 88.172 198.945 87.9138L200.427 87.3882C200.978 87.1915 201.488 86.8688 201.9 86.4508L209.367 78.8684C209.546 78.6871 209.838 78.684 210.019 78.8623C210.201 79.0405 210.204 79.3325 210.025 79.5139L202.559 87.0962C202.051 87.6126 201.421 88.0121 200.738 88.255L199.256 88.7805C198.668 88.9895 198.13 89.343 197.706 89.8009L189.335 98.8064C189.245 98.9047 189.119 98.9539 188.996 98.9539Z"
      fill="#F8F7E5"
    />
    <path
      d="M188.996 98.9539C188.883 98.9539 188.772 98.9139 188.683 98.831C188.495 98.6588 188.486 98.3668 188.658 98.1794L197.029 89.1739C197.555 88.6084 198.216 88.172 198.945 87.9138L200.427 87.3882C200.978 87.1915 201.488 86.8688 201.9 86.4508L209.367 78.8684C209.546 78.6871 209.838 78.684 210.019 78.8623C210.201 79.0405 210.204 79.3325 210.025 79.5139L202.559 87.0962C202.051 87.6126 201.421 88.0121 200.738 88.255L199.256 88.7805C198.668 88.9895 198.13 89.343 197.706 89.8009L189.335 98.8064C189.245 98.9047 189.119 98.9539 188.996 98.9539Z"
      fill="#F8F7E5"
    />
    <path
      d="M79.85 51.3841C68.745 48.5718 62.1976 45.5444 59.9219 42.3172C69.0863 36.2962 76.6978 28.2405 79.5701 18.7617"
      fill="black"
    />
    <path
      d="M89.3964 23.7256L78.6851 66.7856L93.0991 71.691L103.718 30.1646L89.3964 23.7256Z"
      fill="#907A58"
    />
    <path
      d="M98.9107 20.7445C91.9789 14.6927 76.4486 26.4274 76.4486 26.4274C76.4486 26.4274 69.8581 39.4653 72.2692 49.1592C72.9734 51.993 75.3138 54.169 78.2292 55.1495L81.1753 56.1392C81.1753 56.1392 89.9092 55.7089 93.981 52.537C110.345 39.7911 105.781 26.7471 98.9107 20.7475V20.7445Z"
      fill="#907A58"
    />
    <path
      d="M83.2275 56.1947C86.3613 56.3054 90.0824 55.036 93.9819 52.5342"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.223 54.3653C96.7733 54.771 89.6416 53.6953 86.5509 51.2303C93.6641 42.8888 96.6503 33.6283 96.7548 23.7223C65.4696 36.9262 67.3424 9.49189 96.7548 15.4668C120.081 20.2062 108.223 54.3623 108.223 54.3623V54.3653Z"
      fill="black"
    />
    <path
      d="M79.8989 41.6087C78.9547 43.6833 76.0301 41.8269 77.3894 39.6877C78.1459 38.4952 80.5355 36.3007 81.0921 33.4854"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.4265 35.4875C77.7098 34.5147 77.5111 33.6014 76.9829 33.4474C76.4546 33.2935 75.7967 33.9573 75.5134 34.9301C75.2301 35.9028 75.4288 36.8162 75.9571 36.9701C76.4853 37.124 77.1432 36.4602 77.4265 35.4875Z"
      fill="black"
    />
    <path
      d="M85.8279 39.1512C86.1114 38.1785 85.9129 37.2653 85.3846 37.1115C84.8563 36.9577 84.1982 37.6216 83.9147 38.5943C83.6312 39.5669 83.8297 40.4802 84.358 40.634C84.8863 40.7878 85.5444 40.1239 85.8279 39.1512Z"
      fill="black"
    />
    <path
      d="M88.3716 35.9242C86.9785 34.4586 85.5823 34.1175 84.7305 34.0684"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.4928 31.9974C77.9336 30.7284 76.8111 30.6362 75.9746 30.71"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.4585 47.9304C82.744 47.7061 83.9218 47.0362 84.337 46.1943C85.8654 48.6402 82.9869 50.3762 81.4585 47.9304Z"
      fill="black"
      stroke="black"
      strokeWidth="0.808094"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.001 48.2714C81.7631 48.0686 83.6299 47.0117 84.3372 46.1943"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.6686 57.1777L98.9268 58.2012L95.2118 73.9868L75.0776 67.9166L80.6686 57.1777Z"
      fill="#907A58"
    />
    <path
      d="M61.0574 128.778H104.915L125.196 200.204H104.573L84.0977 156.342L78.4083 200.204H59.3198L61.0574 128.778Z"
      fill="#F2DA91"
    />
    <path
      d="M96.0569 66.2478L85.3425 128.779C80.9755 130.497 76.8115 130.531 72.8628 128.779V62.7686C79.8069 67.4926 87.5321 68.6789 96.0538 66.2509L96.0569 66.2478Z"
      fill="#F8F7E5"
    />
    <path
      d="M83.7891 155.727L80.9536 149.61"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M143.036 77.1248L150.512 68.9093C151.506 67.8182 152.911 67.1973 154.387 67.1973H164.782L173.021 66.266C174.082 66.1462 175.121 66.6318 175.708 67.5262C176.136 68.1778 175.924 69.0568 175.241 69.4379L169.075 72.8833C167.593 73.7101 165.981 74.2818 164.308 74.5676L156.638 75.8831L150.525 83.8958L143.036 77.1248Z"
      fill="#907A58"
    />
    <path
      d="M85.3944 138.506C95.1216 139.529 103.92 138.586 111.679 135.432V93.1038C133.668 115.581 133.668 115.196 153.537 86.6187C156.084 83.8064 144.963 73.7529 142.417 76.5652L133.742 86.2929L119.364 67.569C114.871 61.717 108.164 57.9735 100.823 57.2143C97.1206 56.8332 93.707 59.2459 92.8305 62.8604C87.3134 85.669 84.8931 111.969 85.3913 138.503L85.3944 138.506Z"
      fill="#E5B530"
    />
    <path
      d="M57.3413 135.433C62.2741 138.082 67.536 138.94 73.0347 138.506C72.1059 100.637 74.3632 72.6316 80.6707 57.1748C64.3407 57.1748 57.3413 57.0119 57.3413 135.433Z"
      fill="#E5B530"
    />
    <path
      d="M80.6683 57.1777C67.6258 57.1777 63.3573 57.2546 48.8571 71.7462C30.0885 90.5039 32.5764 101.953 57.6003 115.197L59.1103 97.4317L55.2415 91.2723L80.6683 57.1777Z"
      fill="#E5B530"
    />
    <path
      d="M90.5859 114.185L91.3179 117.848C91.4809 118.663 92.1513 119.28 92.9755 119.379L107.552 121.094C108.106 121.158 108.561 120.664 108.45 120.116L107.672 116.345L90.5859 114.185Z"
      fill="#E5B530"
    />
    <path
      d="M90.5859 114.185L91.3179 117.848C91.4809 118.663 92.1513 119.28 92.9755 119.379L107.552 121.094C108.106 121.158 108.561 120.664 108.45 120.116L107.672 116.345"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.5137 118.951L57.3661 130.237L53.6111 131.006C52.4302 131.245 51.3262 130.345 51.3262 129.14V125.19C51.3262 123.77 52.0519 122.446 53.2482 121.68L57.5168 118.951H57.5137Z"
      fill="#907A58"
    />
    <path
      d="M56.6992 113.641V118.248C56.6992 118.687 57.0959 119.022 57.5296 118.949L65.1902 117.651C66.2666 117.47 67.0723 116.567 67.1307 115.475L67.306 112.202L56.6992 113.641Z"
      fill="#E5B530"
    />
    <path
      d="M57.9541 118.878L65.1934 117.651C66.2698 117.47 67.0755 116.567 67.1339 115.475L67.3092 112.202"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.3105 227.783H42.7808C43.608 224.679 46.0191 222.244 49.1159 221.384L56.4752 219.337C57.7453 218.983 58.6402 217.852 58.6894 216.537L59.3229 200.204H78.4084L76.4248 218.541C76.2987 219.712 76.2802 220.917 76.6155 222.045C77.8456 226.194 77.3105 227.786 77.3105 227.786V227.783Z"
      fill="#41556B"
    />
    <path
      d="M125.196 200.204H104.573L112.916 218.562C113.46 219.761 113.593 221.101 113.319 222.389C112.95 224.11 112.765 225.868 112.765 227.632L147.289 228.001C146.425 224.762 143.909 222.223 140.68 221.322L133.853 219.423C132.309 218.993 131.048 217.88 130.43 216.402L125.199 200.201L125.196 200.204Z"
      fill="#41556B"
    />
    <path
      d="M154.433 67.1973H155.955L163.526 62.8759C163.988 62.6147 164.569 62.7253 164.898 63.1433C165.227 63.5582 165.202 64.1514 164.839 64.5387L162.348 67.1973H154.433Z"
      fill="#907A58"
    />
    <path
      d="M132.109 88.127L127.721 93.0477"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.677 89.9112V79.6426"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.5509 64.6436C60.7344 71.6758 58.514 85.2915 57.7021 111.61"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M217.195 58.3693C217.02 58.9194 217.057 59.5157 217.21 60.1396L217.407 62.749L215.87 65.4968L217.207 66.6678C218.757 64.3995 219.579 62.3259 219.674 60.447L221.839 62.829L222.42 66.1484L224.195 65.8748C224.182 63.1496 223.399 60.6468 221.845 58.3662H217.198L217.195 58.3693Z"
      fill="#F2DA91"
    />
    <path
      d="M222.349 58.3698H216.792C218.302 52.3395 219.895 51.2607 222.349 51.8324C224.803 52.4041 225.274 52.5055 225.274 52.5055L227.549 50.3848L228.586 52.2688C226.202 54.8598 226.202 54.8936 222.69 54.66L222.349 58.3698Z"
      fill="#E5B530"
    />
    <path
      d="M223.547 51.1648C224.031 50.7404 223.92 49.8235 223.299 49.1171C222.678 48.4106 221.783 48.182 221.299 48.6064C220.815 49.0309 220.927 49.9477 221.547 50.6542C222.168 51.3607 223.063 51.5893 223.547 51.1648Z"
      fill="#907A58"
    />
    <path
      d="M216.321 43.1723C216.407 43.0339 216.564 42.9479 216.73 42.9633C218.332 43.1108 218.729 41.7646 218.874 39.9881C219.157 36.558 215.202 35.387 214.676 38.7894C211.705 36.7732 210.063 40.2831 212.68 42.6713C213.944 43.8238 215.534 44.4385 216.321 43.1723Z"
      fill="#B6CCAE"
    />
    <circle cx="215.894" cy="39.4225" r="0.307265" fill="black" />
    <circle cx="214.372" cy="40.0582" r="0.307265" fill="black" />
    <path
      d="M214.612 41.1679C215.384 41.4721 216.257 41.0973 216.565 40.3291"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M214.675 38.789C214.11 37.4984 214.66 36.5244 214.66 36.5244"
      stroke="black"
      strokeWidth="0.538729"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M219.885 51.8497C221.336 52.2769 223.661 51.6899 223.962 51.4471C223.261 50.6264 223.068 49.0405 223.068 49.0405C223.64 48.6471 222.902 47.6328 221.013 48.017C218.716 48.4842 219.885 51.8497 219.885 51.8497Z"
      fill="black"
    />
    <path
      d="M227.762 50.7694L228.371 49.7646L228.487 50.1917L229.555 49.8998C229.693 49.863 229.834 49.9521 229.859 50.0903L230.031 51.0552C230.047 51.1473 230.001 51.2364 229.914 51.2733L228.414 51.9432L227.765 50.7633L227.762 50.7694Z"
      fill="#907A58"
    />
    <path
      d="M222.911 68.6926L226.119 68.201C225.999 67.9245 225.738 67.7339 225.439 67.6971L224.726 67.611C224.603 67.5957 224.504 67.5035 224.48 67.3837L224.188 65.875L222.413 66.1485L222.859 67.8231C222.887 67.9306 222.905 68.0412 222.89 68.1518C222.834 68.5543 222.908 68.6957 222.908 68.6957L222.911 68.6926Z"
      fill="#41556B"
    />
    <path
      d="M217.201 66.6642L215.863 65.4932L215.125 66.5566C215.054 66.658 214.953 66.7349 214.836 66.7748C214.679 66.8271 214.528 66.8978 214.387 66.9838L216.02 69.7869C216.241 69.5625 216.324 69.2367 216.241 68.9324L216.066 68.2901C216.026 68.1456 216.057 67.9889 216.146 67.869L217.204 66.6672L217.201 66.6642Z"
      fill="#41556B"
    />
  </svg>
);

export default DuplicateProfileIcon;

interface OpenBookIconProps {
  isLarge?: boolean;
}

const OpenBookIcon = ({ isLarge }: OpenBookIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={isLarge ? '125' : '80'}
    height={isLarge ? '106' : '68'}
    viewBox="0 0 80 68"
    fill="none"
  >
    <g clipPath="url(#clip0_1463_22884)">
      <path
        d="M77.4167 17.1667H74.9167V14.6667C74.9167 13.75 74.1667 13 73.25 13H48.25C44.7667 13 41.7167 14.7833 39.9167 17.4833C38.1167 14.7833 35.0667 13 31.5833 13H6.58333C5.66667 13 4.91667 13.75 4.91667 14.6667V17.1667H2.41667C1.5 17.1667 0.75 17.9167 0.75 18.8333V62.5833C0.75 63.5 1.5 64.25 2.41667 64.25H33.0167C34.9333 65.9167 37.35 66.8333 39.9167 66.8333C42.4833 66.8333 44.9167 65.9167 46.8167 64.25C57.8362 64.25 68.1875 64.25 77.4333 64.25C78.35 64.25 79.1 63.5 79.1 62.5833V18.8333C79.1 17.9167 78.35 17.1667 77.4333 17.1667H77.4167Z"
        fill="#DBE6D7"
      />
      <path
        d="M70.7493 6.75V44.25H9.08268V6.75H2.41602V50.5H33.666C37.116 53.95 42.716 53.95 46.166 50.5H77.416V6.75H70.7493Z"
        fill="white"
        stroke="#3F6655"
        strokeWidth="3.33333"
        strokeLinejoin="round"
      />
      <path
        d="M39.916 12.9987V10.9154C39.916 6.31536 43.6493 2.58203 48.2493 2.58203H70.7493V44.2487H44.0827C41.7827 44.2487 39.916 46.1154 39.916 48.4154V35.9154"
        fill="white"
      />
      <path
        d="M39.916 12.9987V10.9154C39.916 6.31536 43.6493 2.58203 48.2493 2.58203H70.7493V44.2487H44.0827C41.7827 44.2487 39.916 46.1154 39.916 48.4154V35.9154"
        stroke="#3F6655"
        strokeWidth="3.33333"
        strokeLinejoin="round"
      />
      <path
        d="M39.9154 12.9987V10.9154C39.9154 6.31536 36.182 2.58203 31.582 2.58203H9.08203V44.2487H35.7487C38.0487 44.2487 39.9154 46.1154 39.9154 48.4154V35.9154"
        fill="white"
      />
      <path
        d="M39.9154 12.9987V10.9154C39.9154 6.31536 36.182 2.58203 31.582 2.58203H9.08203V44.2487H35.7487C38.0487 44.2487 39.9154 46.1154 39.9154 48.4154V35.9154"
        stroke="#3F6655"
        strokeWidth="3.33333"
        strokeLinejoin="round"
      />
      <path
        d="M58.5501 24.0827C58.5501 16.8109 52.6552 10.916 45.3835 10.916C38.1117 10.916 32.2168 16.8109 32.2168 24.0827C32.2168 31.3544 38.1117 37.2493 45.3835 37.2493C52.6552 37.2493 58.5501 31.3544 58.5501 24.0827Z"
        stroke="#3F6655"
        strokeWidth="3.33333"
        strokeLinejoin="round"
      />
      <path
        d="M64.0626 38.5761C62.9105 37.4241 61.0427 37.4241 59.8907 38.5761C58.7386 39.7282 58.7386 41.596 59.8907 42.7481L70.2969 53.1543C71.449 54.3064 73.3168 54.3064 74.4688 53.1543C75.6209 52.0023 75.6209 50.1344 74.4688 48.9824L64.0626 38.5761Z"
        fill="white"
        stroke="#3F6655"
        strokeWidth="3.33333"
        strokeLinejoin="round"
      />
      <path
        d="M55.7148 34.4141L59.8815 38.5807"
        stroke="#3F6655"
        strokeWidth="3.33333"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1463_22884">
        <rect
          width="78.3333"
          height="66.8667"
          fill="white"
          transform="translate(0.75 0.917969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default OpenBookIcon;

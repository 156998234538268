const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0007 10.8337C9.74694 10.8307 9.49841 10.9055 9.28852 11.0481C9.07863 11.1906 8.91744 11.3941 8.82666 11.631C8.73588 11.8679 8.71987 12.127 8.78077 12.3733C8.84168 12.6196 8.97658 12.8413 9.16732 13.0087V14.167C9.16732 14.388 9.25512 14.6 9.4114 14.7562C9.56768 14.9125 9.77964 15.0003 10.0007 15.0003C10.2217 15.0003 10.4336 14.9125 10.5899 14.7562C10.7462 14.6 10.834 14.388 10.834 14.167V13.0087C11.0247 12.8413 11.1596 12.6196 11.2205 12.3733C11.2814 12.127 11.2654 11.8679 11.1746 11.631C11.0839 11.3941 10.9227 11.1906 10.7128 11.0481C10.5029 10.9055 10.2544 10.8307 10.0007 10.8337ZM14.1673 7.50033V5.83366C14.1673 4.72859 13.7283 3.66878 12.9469 2.88738C12.1655 2.10598 11.1057 1.66699 10.0007 1.66699C8.89558 1.66699 7.83577 2.10598 7.05437 2.88738C6.27297 3.66878 5.83398 4.72859 5.83398 5.83366V7.50033C5.17094 7.50033 4.53506 7.76372 4.06622 8.23256C3.59738 8.7014 3.33398 9.33728 3.33398 10.0003V15.8337C3.33398 16.4967 3.59738 17.1326 4.06622 17.6014C4.53506 18.0703 5.17094 18.3337 5.83398 18.3337H14.1673C14.8304 18.3337 15.4662 18.0703 15.9351 17.6014C16.4039 17.1326 16.6673 16.4967 16.6673 15.8337V10.0003C16.6673 9.33728 16.4039 8.7014 15.9351 8.23256C15.4662 7.76372 14.8304 7.50033 14.1673 7.50033ZM7.50065 5.83366C7.50065 5.17062 7.76404 4.53473 8.23288 4.06589C8.70172 3.59705 9.33761 3.33366 10.0007 3.33366C10.6637 3.33366 11.2996 3.59705 11.7684 4.06589C12.2373 4.53473 12.5007 5.17062 12.5007 5.83366V7.50033H7.50065V5.83366ZM15.0007 15.8337C15.0007 16.0547 14.9129 16.2666 14.7566 16.4229C14.6003 16.5792 14.3883 16.667 14.1673 16.667H5.83398C5.61297 16.667 5.40101 16.5792 5.24473 16.4229C5.08845 16.2666 5.00065 16.0547 5.00065 15.8337V10.0003C5.00065 9.77931 5.08845 9.56735 5.24473 9.41107C5.40101 9.25479 5.61297 9.16699 5.83398 9.16699H14.1673C14.3883 9.16699 14.6003 9.25479 14.7566 9.41107C14.9129 9.56735 15.0007 9.77931 15.0007 10.0003V15.8337Z"
      fill="#41556B"
    />
  </svg>
);

export default LockIcon;

const EnvelopeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
  >
    <rect
      x="13.332"
      y="14.666"
      width="64"
      height="40"
      rx="5.33333"
      fill="#DBE6D7"
    />
    <rect
      x="5.33398"
      y="25.334"
      width="64"
      height="40"
      rx="5.33333"
      fill="white"
      stroke="#3F6655"
      strokeWidth="2.66667"
      strokeLinejoin="round"
    />
    <path
      d="M13.334 33.334L25.334 44.0007M25.334 44.0007L33.7907 51.5177C35.8114 53.3139 38.8565 53.3139 40.8773 51.5177L49.334 44.0007M25.334 44.0007L14.6673 54.6673M49.334 44.0007L61.334 33.334M49.334 44.0007L61.334 56.0006"
      stroke="#3F6655"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EnvelopeIcon;

import { colors } from '@/constants/colors';

const CheckIcon = ({
  width = '10',
  height = '8',
  viewBox = '0 0 10 8',
  strokeColor = colors.white,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1L3.5 6.5L1 4"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;

const SelectIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="14"
    viewBox="0 0 23 14"
    fill="none"
  >
    <path
      d="M20.6566 1H2.3434C1.46271 1 1.01206 2.05606 1.62141 2.69191L10.778 12.2466C11.1717 12.6574 11.8283 12.6574 12.222 12.2466L21.3786 2.69191C21.9879 2.05606 21.5373 1 20.6566 1Z"
      fill="#41556B"
      stroke="#41556B"
    />
  </svg>
);

export default SelectIcon;

import { colors } from '@/constants/colors';

const QuoteIcon = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ooui:quotes-ltr">
      <path
        id="Vector"
        d="M29.9004 32.2008L27.6004 36.8008L32.2004 36.8008C37.2834 36.8008 41.4004 30.3838 41.4004 25.3008L41.4004 9.20079L25.3004 9.20079L25.3004 25.3008L34.5004 25.3008C34.5004 32.2008 29.9004 32.2008 29.9004 32.2008ZM13.8004 25.3008C13.8004 32.2008 9.2004 32.2008 9.2004 32.2008L6.9004 36.8008L11.5004 36.8008C16.5834 36.8008 20.7004 30.3838 20.7004 25.3008L20.7004 9.20078L4.6004 9.20078L4.6004 25.3008L13.8004 25.3008Z"
        fill={colors.cucumber}
        fillOpacity="0.5"
      />
    </g>
  </svg>
);

export default QuoteIcon;

import { Box } from '@mui/material';
import Image from 'next/image';

import LogoImage from '@/assets/images/logo.svg';

interface LogoProps {
  [x: string]: any;
}

const Logo = (props: LogoProps) => {
  const { ...rest } = props;

  return (
    <Box {...rest}>
      <Image
        priority
        width={189}
        src={LogoImage}
        alt="Main-Logo"
        data-testid="logo"
      />
    </Box>
  );
};

export default Logo;

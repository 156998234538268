const MatchWithRDPicture = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="242"
    height="220"
    viewBox="0 0 242 220"
    fill="none"
  >
    <path
      d="M85.8459 39.9994L85.4516 37.7568H68.3713L67.959 40.1141L68.1004 45.9152H85.7225L85.8459 39.9994Z"
      fill="#907A58"
    />
    <path
      d="M93.5481 24.0358L94.0236 23.1906C94.9777 21.4944 94.8784 19.3997 93.7708 17.797L94.1982 16.6953C95.4713 13.4175 93.3585 9.8076 89.8822 9.32468C89.0455 6.48146 86.4451 4.52864 83.4865 4.52864C82.8815 2.88972 81.1901 1.92085 79.4745 2.23475L79.2578 2.27399C78.0659 0.740702 75.7575 0.740702 74.5656 2.27399L74.3489 2.23475C72.6334 1.92387 70.9419 2.88972 70.3369 4.52864C67.3814 4.52864 64.7779 6.48146 63.9412 9.32468C60.465 9.8076 58.3521 13.4175 59.6252 16.6953L60.0526 17.797C58.945 19.3967 58.8457 21.4914 59.7998 23.1906L60.2754 24.0358H93.5481Z"
      fill="black"
    />
    <path
      d="M68.0225 40.416C68.0225 47.3973 85.801 47.3973 85.801 40.416V110.162H68.0225V40.416Z"
      fill="white"
    />
    <path
      d="M100.931 190.717L99.8744 98.0859H53.4431L52.3867 190.717H72.8319L74.6016 129.204H78.7159L80.4857 190.717H100.931Z"
      fill="#F2DA91"
    />
    <path
      d="M51.5618 112.474C56.7867 115.003 64.1816 117.155 73.6894 118.8C75.1311 113.25 76.9098 111.602 76.9098 111.602V76.2156C76.9098 76.2156 66.1921 44.7772 68.2899 38.4268C65.7346 39.3111 63.3419 40.4279 61.1538 41.8495C60.3081 42.3988 59.8777 43.4009 60.0823 44.3909L55.6731 46.3829C49.3225 49.2503 45.2383 55.5857 45.2383 62.57V82.9856C45.2383 86.8067 47.8537 90.1268 51.5587 91.0112V112.48L51.5618 112.474Z"
      fill="#E5B530"
    />
    <path
      d="M70.1532 77.9326L65.8252 78.2827C67.3842 78.7445 68.841 79.315 70.1532 80.0364V77.9356V77.9326Z"
      fill="#907A58"
    />
    <path
      d="M80.1335 118.8C78.7581 113.509 76.9131 111.602 76.9131 111.602C76.9131 111.602 76.9131 93.2236 76.9131 76.2156C76.9131 76.2156 87.6308 44.7802 85.533 38.4268C85.533 38.4268 93.1898 42.1272 93.4637 43.1202C93.7375 44.1132 93.7376 44.3879 93.7376 44.3879L98.1468 46.3799C104.497 49.2473 108.582 55.5826 108.582 62.5669V82.9826C108.582 86.8037 105.966 90.1238 102.261 91.0082V112.477C97.0362 115.006 89.6413 117.158 80.1335 118.803V118.8Z"
      fill="#E5B530"
    />
    <path
      d="M55.3242 77.0068C63.4475 77.0068 67.0472 77.8852 76.6573 83.6048C86.2674 89.3274 88.5127 91.4161 97.3222 91.4161"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.7246 91.416H70.6883C74.8177 91.416 78.9229 90.8094 82.8747 89.6202L85.5323 88.8203"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.2764 78.2172L95.2335 77.4324C94.138 77.1487 93.0123 77.0068 91.8837 77.0068H70.1533V80.0342"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.7398 28.9817C66.3416 28.4504 67.0605 26.2621 66.3455 24.0938C65.6304 21.9256 63.7522 20.5986 62.1504 21.1299C60.5485 21.6611 59.8297 23.8495 60.5447 26.0177C61.2597 28.1859 63.1379 29.513 64.7398 28.9817Z"
      fill="#907A58"
    />
    <path
      d="M93.2801 26.0146C93.9951 23.8463 93.2762 21.658 91.6744 21.1267C90.0725 20.5954 88.1943 21.9225 87.4793 24.0907C86.7642 26.2589 87.4831 28.4473 89.085 28.9785C90.6868 29.5098 92.565 28.1828 93.2801 26.0146Z"
      fill="#907A58"
    />
    <path
      d="M89.5145 18.6207L88.3257 32.2512C88.109 34.7262 86.7726 36.9627 84.6959 38.3179L82.4236 39.8029C81.1866 40.6118 79.7419 41.0404 78.2671 41.0404H75.5553C74.0806 41.0404 72.6359 40.6118 71.3989 39.8029L69.1265 38.3179C67.0528 36.9627 65.7135 34.7262 65.4968 32.2512L64.3079 18.6207C63.6608 11.2048 69.4877 4.82715 76.9097 4.82715C84.3317 4.82715 90.1586 11.2048 89.5115 18.6207H89.5145Z"
      fill="#907A58"
    />
    <path
      d="M82.4241 39.7998C81.1871 40.6087 79.7424 41.0373 78.2677 41.0373H75.5559C74.0811 41.0373 72.6364 40.6087 71.3994 39.7998"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.2921 5.9325L76.91 4.00684L68.5278 5.9325L62.5986 12.2196V17.5438L64.567 21.5943L64.9011 25.4215L65.175 21.7603C65.2592 20.6285 65.8281 19.5872 66.737 18.905C67.5166 18.3195 67.7393 17.245 67.2577 16.3969L66.8785 15.7268C65.6204 13.5054 66.4631 10.6802 68.7325 9.52122L70.7129 8.50708C71.8626 7.91852 73.2682 8.33806 73.9123 9.46086C74.5293 10.5354 75.673 11.1994 76.91 11.1994C78.147 11.1994 79.2907 10.5384 79.9077 9.46086C80.5518 8.34108 81.9573 7.92154 83.107 8.50708L85.0874 9.52122C87.3568 10.6802 88.2025 13.5054 86.9414 15.7268L86.5622 16.3969C86.0806 17.245 86.3034 18.3195 87.0829 18.905C87.9888 19.5872 88.5577 20.6285 88.6449 21.7603L88.9188 25.4215L89.2529 21.5943L91.2213 17.5438V12.2196L85.2921 5.9325Z"
      fill="black"
    />
    <path
      d="M72.2862 23.9938C73.0033 23.6356 73.3492 22.871 73.0586 22.2861C72.7681 21.7012 71.9512 21.5175 71.2341 21.8757C70.5169 22.234 70.1711 22.9986 70.4616 23.5835C70.7521 24.1683 71.569 24.3521 72.2862 23.9938Z"
      fill="black"
    />
    <path
      d="M83.6016 23.6084C83.8921 23.0235 83.5463 22.259 82.8291 21.9007C82.112 21.5425 81.2951 21.7262 81.0046 22.3111C80.714 22.896 81.0599 23.6605 81.777 24.0188C82.4942 24.377 83.311 24.1933 83.6016 23.6084Z"
      fill="black"
    />
    <path
      d="M72.9492 33.8623C74.9821 35.3562 79.4512 35.3562 81.4375 33.8623"
      stroke="black"
      strokeWidth="0.865945"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.7383 20.4499C70.7909 18.3915 74.2672 18.0745 75.134 17.5977"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.3703 20.4499C83.3177 18.3915 79.8414 18.0745 78.9746 17.5977"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.9425 26.4505C72.6995 28.5874 77.8281 31.4487 79.2397 29.4778C80.6512 27.5068 77.5331 25.4242 77.5331 20.6191"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.3252 128.873C75.5313 129.522 77.8789 129.522 80.0851 128.873"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.5186 46.1895C62.4448 53.8408 66.9143 62.4851 66.9143 62.4851"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.99 61.5625C68.3075 62.139 66.8297 63.0656 65.5205 64.2789C66.5829 67.098 70.0953 71.9695 72.4911 76.8199"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.6809 46.1895C90.7546 53.8408 86.2852 62.4851 86.2852 62.4851"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.2094 61.5625C84.8919 62.139 86.3697 63.0656 87.6789 64.2789C86.6165 67.098 83.3088 71.559 80.916 76.4064"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="76.9127"
      cy="53.9436"
      rx="0.902923"
      ry="0.905483"
      fill="#1F332A"
    />
    <ellipse
      cx="76.9127"
      cy="60.5871"
      rx="0.902923"
      ry="0.905483"
      fill="#1F332A"
    />
    <ellipse
      cx="76.9127"
      cy="67.2268"
      rx="0.902923"
      ry="0.905483"
      fill="#1F332A"
    />
    <path
      d="M58.6133 57.0078C59.748 62.486 59.6757 66.0475 59.6757 77.0069"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.6493 57.3096C93.5146 62.7877 93.5869 66.0475 93.5869 77.0068"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.9131 108.58V91.0049"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.5977 50.3073L76.5607 46.5254"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.5236 50.3073L76.5605 46.5254"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.2071 190.717L70.9896 204.909H45.4219C46.0058 202.262 47.7033 199.998 50.0749 198.7L53.6776 196.726L54.9447 190.717H70.2071Z"
      fill="#907A58"
    />
    <path
      d="M83.0706 190.717L82.2881 204.909H107.856C107.272 202.262 105.574 199.998 103.203 198.7L99.6001 196.726L98.333 190.717H83.0706Z"
      fill="#907A58"
    />
    <path
      d="M173.589 40.7053C178.862 43.1803 180.65 37.4697 180.65 37.4697C182.109 37.8048 183.846 36.8329 183.888 35.2875C183.939 33.4615 182.633 32.5771 182.046 28.6202C180.159 15.9494 179.139 7.53147 170.79 2.46378C162.441 -2.6039 148.617 0.112545 150.907 10.924C145.658 10.924 145.388 24.5515 144.933 28.6171C144.488 32.5922 143.04 33.4585 143.091 35.2845C143.133 36.8299 144.873 37.8018 146.33 37.4667C146.33 37.4667 148.117 43.1803 153.39 40.7023H173.592L173.589 40.7053Z"
      fill="#635A4C"
    />
    <path
      d="M147.151 36.7994C147.672 36.1444 147.853 35.3506 147.853 35.3506L147.151 36.7994Z"
      fill="#635A4C"
    />
    <path
      d="M147.151 36.7994C147.672 36.1444 147.853 35.3506 147.853 35.3506"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M179.919 36.911C179.326 36.241 179.124 35.3506 179.124 35.3506L179.919 36.911Z"
      fill="#635A4C"
    />
    <path
      d="M179.919 36.911C179.326 36.241 179.124 35.3506 179.124 35.3506"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M153.466 47.2311L145.009 47.0983L138.363 115.139H188.406L181.761 47.0983L173.303 47.2311V44.2883C173.303 37.4156 153.463 37.4156 153.463 44.2883V47.2311H153.466Z"
      fill="#202A35"
    />
    <path
      d="M151.296 32.5953C153.197 32.5953 154.739 30.967 154.739 28.9583C154.739 26.9496 153.197 25.3213 151.296 25.3213C149.394 25.3213 147.853 26.9496 147.853 28.9583C147.853 30.967 149.394 32.5953 151.296 32.5953Z"
      fill="#EFDCBD"
    />
    <path
      d="M175.476 32.5953C177.378 32.5953 178.919 30.967 178.919 28.9583C178.919 26.9496 177.378 25.3213 175.476 25.3213C173.575 25.3213 172.033 26.9496 172.033 28.9583C172.033 30.967 173.575 32.5953 175.476 32.5953Z"
      fill="#EFDCBD"
    />
    <path
      d="M175.554 24.6177L173.905 38.9786C173.67 41.031 172.211 42.7303 170.221 43.2706L166.486 44.2817C164.455 44.8311 162.315 44.8311 160.283 44.2817L156.548 43.2706C154.558 42.7334 153.099 41.031 152.864 38.9786L151.215 24.6177C150.378 17.3225 156.063 10.9268 163.386 10.9268C170.709 10.9268 176.394 17.3255 175.557 24.6177H175.554Z"
      fill="#EFDCBD"
    />
    <path
      d="M163.489 41.4177C163.489 41.4177 160.413 41.5354 158.005 40.4277V44.2368C158.005 45.8697 159.735 46.917 161.177 46.1594C162.465 47.412 164.512 47.412 165.8 46.1594C167.242 46.917 168.972 45.8697 168.972 44.2368V40.4277C166.565 41.5354 163.489 41.4177 163.489 41.4177Z"
      fill="#635A4C"
    />
    <path
      d="M152.067 24.8174H159.676C160.615 24.8174 161.28 25.738 160.988 26.6314L159.751 30.4344C159.435 31.4033 158.535 32.0582 157.518 32.0582H153.355C152.422 32.0582 151.621 31.3972 151.444 30.4797L150.673 26.5137C150.502 25.6353 151.173 24.8174 152.067 24.8174Z"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M160.987 26.6309C158.462 34.5025 158.435 34.5025 163.067 34.5025"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M174.486 24.8174H166.878C165.939 24.8174 165.274 25.738 165.566 26.6314L166.803 30.4344C167.119 31.4033 168.018 32.0582 169.036 32.0582H173.198C174.131 32.0582 174.932 31.3972 175.109 30.4797L175.88 26.5137C176.051 25.6353 175.38 24.8174 174.486 24.8174Z"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M160.987 26.6314L161.05 26.4352C161.364 25.4693 162.263 24.8174 163.275 24.8174C164.289 24.8174 165.186 25.4693 165.499 26.4352L165.562 26.6314"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M154.038 23.6885C153.758 23.6885 153.487 23.5315 153.358 23.2599C153.177 22.8826 153.337 22.4329 153.713 22.2548L158.399 20.0092C158.775 19.8281 159.224 19.9881 159.401 20.3653C159.582 20.7426 159.422 21.1924 159.046 21.3704L154.36 23.616C154.255 23.6673 154.143 23.6915 154.035 23.6915L154.038 23.6885Z"
      fill="#635A4C"
    />
    <path
      d="M172.566 23.6882C172.458 23.6882 172.347 23.6641 172.241 23.6128L167.555 21.3672C167.179 21.1861 167.022 20.7363 167.2 20.3621C167.378 19.9848 167.829 19.8278 168.202 20.0059L172.888 22.2515C173.265 22.4326 173.421 22.8823 173.244 23.2566C173.114 23.5283 172.846 23.6852 172.563 23.6852L172.566 23.6882Z"
      fill="#635A4C"
    />
    <path
      d="M158.725 36.6309C161.692 37.968 164.813 37.9619 168.085 36.6309"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M157.198 40.0898C161.132 41.8646 165.27 41.8555 169.607 40.0898"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M143.772 193.994H158.782L159.284 204.905H138.574C138.574 202.488 139.631 200.188 141.461 198.615L142.872 197.405L143.772 193.994Z"
      fill="#41556B"
    />
    <path
      d="M183.001 193.994H167.992L167.489 204.905H188.199C188.199 202.488 187.143 200.188 185.313 198.615L183.901 197.405L183.001 193.994Z"
      fill="#41556B"
    />
    <path
      d="M184.049 193.994L183.146 106.087H143.468L142.565 193.994H160.037L161.551 118.978H165.066L166.577 193.994H184.049Z"
      fill="#A1BBD7"
    />
    <path
      d="M157.716 118.202C161.249 119.243 165.005 119.243 168.539 118.202"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M157.711 12.3933C162.153 10.1326 160.79 21.3787 170.867 18.6442C172.534 21.5327 176.248 20.76 176.248 20.76L173.368 8.38505L158.969 7.24414L157.711 12.3933Z"
      fill="#635A4C"
    />
    <path
      d="M159.034 11.042C156.93 9.1073 155.335 10.9273 155.335 10.9273L159.034 11.042Z"
      fill="#635A4C"
    />
    <path
      d="M159.034 11.042C156.93 9.1073 155.335 10.9273 155.335 10.9273"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M172.033 18.1074C174.576 16.6797 174.348 13.251 174.348 13.251L172.033 18.1074Z"
      fill="#635A4C"
    />
    <path
      d="M172.033 18.1074C174.576 16.6797 174.348 13.251 174.348 13.251"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M176.506 20.4015C177.415 19.7647 177.613 18.5 177.613 18.5L176.506 20.4015Z"
      fill="#635A4C"
    />
    <path
      d="M176.506 20.4015C177.415 19.7647 177.613 18.5 177.613 18.5"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M166.384 45.3477C166.793 44.4483 166.7 43.4492 166.7 43.4492"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M160.593 45.3477C160.183 44.4483 160.277 43.4492 160.277 43.4492"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.417 85.6018L181.763 47.0977C187.632 48.2838 191.978 50.6049 193.528 59.1527L195.698 81.337C195.996 84.3764 196.053 87.4369 195.872 90.4884L193.871 124.106H182.256L184.417 85.6018Z"
      fill="#202A35"
    />
    <path
      d="M142.354 85.6018L145.008 47.0977C139.139 48.2838 134.793 50.6049 133.243 59.1527L131.073 81.337C130.775 84.3764 130.718 87.4369 130.899 90.4884L132.9 124.106H144.515L142.354 85.6018Z"
      fill="#202A35"
    />
    <path
      d="M183.331 124.106H192.24L192.86 128.208C193.08 129.669 192.974 131.157 192.553 132.573L191.499 136.11C191.34 136.647 190.807 136.982 190.253 136.892L188.553 136.614C187.954 136.515 187.545 135.953 187.632 135.353L188.261 131.042L187.268 128.978C186.955 128.326 186.07 128.235 185.63 128.809C185.501 128.978 185.429 129.186 185.429 129.4V131.048C185.429 131.649 184.944 132.135 184.345 132.135C183.81 132.135 183.352 131.74 183.274 131.208L182.94 128.975C182.822 128.196 182.825 127.402 182.946 126.624L183.331 124.109V124.106Z"
      fill="#EFDCBD"
    />
    <path
      d="M143.284 124.106H134.375L133.755 128.208C133.536 129.669 133.641 131.157 134.062 132.573L135.116 136.11C135.275 136.647 135.808 136.982 136.362 136.892L138.062 136.614C138.661 136.515 139.07 135.953 138.983 135.353L138.354 131.042L139.347 128.978C139.66 128.326 140.545 128.235 140.985 128.809C141.114 128.978 141.186 129.186 141.186 129.4V131.048C141.186 131.649 141.671 132.135 142.27 132.135C142.806 132.135 143.263 131.74 143.341 131.208L143.675 128.975C143.793 128.196 143.79 127.402 143.669 126.624L143.284 124.109V124.106Z"
      fill="#EFDCBD"
    />
    <path
      d="M182.347 206.893H196.761L197.363 219.998H175.019C175.019 218.262 175.858 216.636 177.27 215.637L181.267 212.802L182.347 206.893Z"
      fill="#2F3566"
    />
    <path
      d="M227.843 206.893H213.429L212.827 219.998H235.171C235.171 218.262 234.332 216.636 232.92 215.637L228.923 212.802L227.843 206.893Z"
      fill="#2F3566"
    />
    <path
      d="M214.979 56.1533L215.641 59.7421V67.1157H194.549V59.7421L195.208 56.1533H214.979Z"
      fill="#907A58"
    />
    <path
      d="M215.641 59.7412C215.641 59.7412 211.174 66.1218 205.095 66.1218C199.015 66.1218 194.548 59.7412 194.548 59.7412L191.479 63.333L193.158 70.3987L199.966 72.4512L205.089 75.8347L210.217 72.4512L217.025 70.3987L218.705 63.333L215.635 59.7412H215.641Z"
      fill="#F8F7E5"
    />
    <path
      d="M203.565 68.0078L199.969 72.4507"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M210.22 72.4507L206.62 68.0078"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M234.349 141.845L234.623 136.062V120.971H175.566V136.062L175.84 141.845C174.78 142.859 174.416 144.402 174.907 145.784L175.894 148.561C176.095 149.128 176.213 149.723 176.24 150.323L178.882 206.892H199.65L203.833 147.776H206.358L210.542 206.892H231.309L233.952 150.323C233.979 149.723 234.096 149.128 234.298 148.561L235.285 145.784C235.776 144.402 235.411 142.859 234.352 141.845H234.349Z"
      fill="#B4CAFA"
    />
    <path
      d="M225.979 124.828C225.979 124.828 223.725 129.316 205.095 129.316C186.464 129.316 184.21 124.828 184.21 124.828V130.521C184.21 130.521 186.464 135.009 205.095 135.009C223.725 135.009 225.979 130.521 225.979 130.521V124.828Z"
      fill="#2F3566"
    />
    <path
      d="M201.447 129.141V134.836H208.703V129.135H210.964V136.357C210.964 136.702 210.687 136.979 210.344 136.979H199.804C199.46 136.979 199.184 136.702 199.184 136.357V129.141H201.444H201.447Z"
      fill="#202A35"
    />
    <path d="M221.717 127.281V134.483L219.246 135.195V128.054" fill="#B4CAFA" />
    <path
      d="M221.26 134.616L219.674 135.072"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.517 133.529L217.143 141.932C216.562 142.699 216.622 143.776 217.284 144.474C218.018 145.243 219.231 145.289 220.017 144.57L222.918 141.92C223.301 141.57 223.803 141.389 224.321 141.41L234.349 141.845L234.554 138.009L223.517 133.526V133.529Z"
      fill="#907A58"
    />
    <path
      d="M186.672 133.529L193.047 141.932C193.628 142.699 193.568 143.776 192.906 144.474C192.171 145.243 190.958 145.289 190.173 144.57L186.672 141.374C182.967 142.403 179.335 142.753 175.84 141.845L175.636 138.009L186.672 133.526V133.529Z"
      fill="#907A58"
    />
    <path
      d="M240.459 88.4399C236.483 69.6722 236.92 69.6451 221.847 64.8702C222.064 64.4235 222.058 64.4355 220.508 63.6417C219.466 63.1085 218.866 63.0059 218.708 63.3339C216.339 69.0233 211.156 73.5688 205.095 73.5688C199.033 73.5688 193.847 69.0233 191.482 63.3339C191.313 62.9868 191.232 62.8208 189.658 63.5844C188.084 64.348 188.174 64.5261 188.343 64.8732C173.27 69.6481 173.709 69.6722 169.73 88.4429C166.759 102.462 168.069 118.408 173.661 136.283C175.47 142.014 188.313 137.94 186.504 132.208C185.652 129.488 184.887 127.047 184.207 124.828C184.207 124.828 186.462 129.316 205.092 129.316C223.722 129.316 225.976 124.828 225.976 124.828C225.293 127.047 224.529 129.488 223.68 132.208C221.871 137.94 234.717 142.014 236.523 136.283C242.115 118.408 243.425 102.462 240.453 88.4429L240.459 88.4399ZM205.092 77.4594V77.4202L205.098 77.4594H205.095H205.092Z"
      fill="#5E69BC"
    />
    <path
      d="M191.105 50.8112C193.007 50.8112 194.548 48.6315 194.548 45.9427C194.548 43.2539 193.007 41.0742 191.105 41.0742C189.204 41.0742 187.662 43.2539 187.662 45.9427C187.662 48.6315 189.204 50.8112 191.105 50.8112Z"
      fill="#907A58"
    />
    <path
      d="M219.084 50.8112C220.985 50.8112 222.527 48.6315 222.527 45.9427C222.527 43.2539 220.985 41.0742 219.084 41.0742C217.182 41.0742 215.641 43.2539 215.641 45.9427C215.641 48.6315 217.182 50.8112 219.084 50.8112Z"
      fill="#907A58"
    />
    <path
      d="M205.095 63.3337C205.095 63.3337 208.032 63.6235 212.324 60.1464C215.033 59.3526 219.225 57.5628 218.53 43.06C222.097 32.1338 216.026 25.0801 205.095 25.0801C194.163 25.0801 188.093 32.1338 191.659 43.06C190.967 57.5628 195.16 59.3496 197.865 60.1464C202.157 63.6235 205.095 63.3337 205.095 63.3337Z"
      fill="#907A58"
    />
    <path
      d="M191.666 48.933C191.563 47.2519 191.551 45.3081 191.66 43.0625"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M212.324 60.1465C208.032 63.6235 205.095 63.3338 205.095 63.3338C205.095 63.3338 202.157 63.6235 197.865 60.1465"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M218.53 43.0625C218.633 45.2266 218.63 47.11 218.536 48.7489"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M206.576 49.7847C202.215 49.7847 200.851 49.335 204.06 44.21"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M198.662 44.7526C199.578 44.3696 200.152 43.6551 199.945 43.1566C199.738 42.6581 198.827 42.5644 197.911 42.9473C196.995 43.3302 196.421 44.0447 196.628 44.5433C196.835 45.0418 197.746 45.1355 198.662 44.7526Z"
      fill="black"
    />
    <path
      d="M213.004 44.5446C213.211 44.0461 212.636 43.3315 211.72 42.9486C210.804 42.5657 209.894 42.6594 209.687 43.1579C209.48 43.6564 210.054 44.371 210.97 44.7539C211.886 45.1368 212.796 45.0431 213.004 44.5446Z"
      fill="black"
    />
    <path
      d="M200.36 36.7422C199.68 38.1185 196.511 37.2281 195.512 39.4918"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M209.272 36.7422C209.953 38.1185 213.122 37.2281 214.121 39.4918"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M197.938 51.7646C201.32 54.8674 208.625 54.9399 212.24 51.7646"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="205.094"
      cy="70.9787"
      rx="0.902923"
      ry="0.905483"
      fill="black"
    />
    <path
      d="M177.429 109.139C179.223 110.907 180.55 113.093 181.296 115.501C181.985 117.717 183.683 122.528 183.683 122.528"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M182.079 83.9453C179.37 97.3223 180.664 113.835 180.664 113.835"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M232.76 109.139C230.966 110.907 229.639 113.093 228.892 115.501C228.203 117.717 227.475 120.056 226.701 122.528"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M228.11 83.9453C230.819 97.3223 229.525 113.835 229.525 113.835"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M221.041 66.9072C218.467 72.4488 212.255 76.8585 205.095 76.8585H205.098C197.938 76.8585 191.726 72.4488 189.152 66.9072"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M201.868 147.293C204.074 147.942 206.422 147.942 208.628 147.293"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M186.281 155.11C188.752 152.505 189.577 149.771 189.577 149.771"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M177.862 150.8C179.093 153.552 180.758 154.539 180.758 154.539"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M224.074 155.11C221.603 152.505 220.778 149.771 220.778 149.771"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M232.489 150.8C231.258 153.552 229.594 154.539 229.594 154.539"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M188.233 127.281V134.483L190.704 135.195V128.054" fill="#B4CAFA" />
    <path
      d="M188.634 134.598L190.364 135.099"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.041 73.5197C133.506 73.5197 145.232 63.1726 145.232 50.4088C145.232 37.645 133.506 27.2979 119.041 27.2979C104.577 27.2979 92.8506 37.645 92.8506 50.4088C92.8506 63.1726 104.577 73.5197 119.041 73.5197Z"
      fill="black"
    />
    <path
      d="M105.248 64.4048C107.41 64.4048 109.163 62.5535 109.163 60.2698C109.163 57.9861 107.41 56.1348 105.248 56.1348C103.085 56.1348 101.332 57.9861 101.332 60.2698C101.332 62.5535 103.085 64.4048 105.248 64.4048Z"
      fill="#907A58"
    />
    <path
      d="M132.735 64.4048C134.898 64.4048 136.651 62.5535 136.651 60.2698C136.651 57.9861 134.898 56.1348 132.735 56.1348C130.572 56.1348 128.819 57.9861 128.819 60.2698C128.819 62.5535 130.572 64.4048 132.735 64.4048Z"
      fill="#907A58"
    />
    <path
      d="M100.089 80.2686C97.0457 81.2736 94.689 83.7154 93.7861 86.7971C91.7756 93.6546 90.9329 100.805 91.297 107.946L91.5288 112.471C91.6161 114.158 91.601 115.845 91.4897 117.532L90.2045 136.94C90.036 139.502 90.1684 142.077 90.6048 144.609L91.6582 150.73V156.142C91.6582 157.386 91.9532 158.611 92.519 159.719L93.1932 161.035C93.4912 161.617 94.0871 161.982 94.7402 161.982H96.5069C97.1299 161.982 97.6356 161.475 97.6356 160.851V158.203L98.4241 157.093C98.8726 156.462 99.1194 155.707 99.1314 154.935L99.1465 152.013L102.815 81.8803L100.095 80.2746L100.089 80.2686Z"
      fill="#907A58"
    />
    <path
      d="M100.089 105.172C100.089 113.038 96.167 117.626 96.167 129.859C96.167 142.092 100.525 152.14 100.525 169.619C100.525 187.098 100.525 199.361 100.525 199.361H137.557C137.557 199.361 137.557 187.095 137.557 169.619C137.557 152.143 141.915 142.092 141.915 129.859C141.915 117.626 137.993 113.038 137.993 105.172C142.132 94.9043 136.034 89.0066 137.993 80.2686L129.933 77.6035C129.933 87.8506 108.149 87.8506 108.149 77.6035L100.089 80.2686L101.226 85.8494L100.089 105.172Z"
      fill="#D1805D"
    />
    <path
      d="M128.624 114.653C127.408 114.94 126.156 115.091 124.871 115.079L126.554 129.709C127.815 129.85 129.061 129.706 130.295 129.28L128.624 114.65V114.653Z"
      fill="#F8F7E5"
    />
    <path
      d="M148.769 127.107L147.026 111.841L124.88 114.385C124.546 114.424 124.305 114.726 124.341 115.064L126.027 129.815C126.06 130.113 126.307 130.343 126.608 130.358C127.339 130.397 128.074 130.379 128.766 130.301C129.533 130.216 144.994 128.206 148.242 127.783C148.573 127.741 148.808 127.439 148.769 127.107ZM126.536 129.543L124.874 115.082C126.159 115.092 127.411 114.944 128.627 114.651L130.28 129.114C130.292 129.214 130.232 129.311 130.135 129.341C129.01 129.706 127.875 129.839 126.728 129.73C126.629 129.721 126.551 129.643 126.539 129.543H126.536Z"
      fill="#8E4221"
    />
    <path
      d="M110.974 107.602C110.974 107.602 103.51 111.656 100.088 105.172C96.6661 98.6891 102.048 89.0065 100.088 80.2686H107.059L110.974 107.602Z"
      fill="#D1805D"
    />
    <path
      d="M110.975 107.603C110.975 107.603 105.626 110.506 101.909 107.467"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M136.024 107.463C132.307 110.502 126.959 107.599 126.959 107.599"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M126.207 70.6973C126.207 74.3071 125.68 76.1785 129.93 77.6031C129.93 87.8501 108.146 87.8501 108.146 77.6031C112.395 76.1785 111.869 74.3071 111.869 70.6973H126.207Z"
      fill="#907A58"
    />
    <path
      d="M104.381 56.0015C104.381 65.7717 109.973 71.268 116.266 72.4904C117.663 73.5196 119.303 73.5196 120.871 72.6292C127.499 71.7509 133.602 66.2094 133.602 56.0046C133.602 43.4425 104.381 43.4425 104.381 56.0046V56.0015Z"
      fill="#907A58"
    />
    <path
      d="M132.482 62.9591C133.042 61.3836 133.412 59.6209 133.545 57.665"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.438 57.6709C104.57 59.6177 104.94 61.3773 105.497 62.9468"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.129 71.8984C114.827 72.152 115.54 72.3482 116.266 72.49C117.662 73.5192 119.303 73.5192 120.871 72.6289C121.873 72.4961 122.866 72.2576 123.826 71.9105"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.164 47.8221C118.941 54.1333 126.598 47.8221 126.598 47.8221L119.471 44.2998L111.164 47.8221Z"
      fill="black"
    />
    <path
      d="M104.38 56.0017C104.38 43.4426 133.602 43.4426 133.602 56.0017C136.85 34.3878 101.064 35.1484 104.38 56.0017Z"
      fill="#8E4221"
    />
    <path
      d="M120.462 61.6854C117.139 63.6171 114.86 60.955 116.88 59.4609"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.302 59.8039C112.953 59.8039 113.482 59.0202 113.482 58.0533C113.482 57.0865 112.953 56.3027 112.302 56.3027C111.65 56.3027 111.122 57.0865 111.122 58.0533C111.122 59.0202 111.65 59.8039 112.302 59.8039Z"
      fill="black"
    />
    <path
      d="M113.758 53.1552C112.154 52.9862 110.427 53.5687 109.587 55.108"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M113.476 64.7969C116.211 67.3051 122.117 67.3654 125.042 64.7969"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.041 199.357V141.929"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M131.657 129.684L131.901 129.904C130.264 130.115 128.961 130.281 128.765 130.302C128.591 130.321 128.413 130.336 128.235 130.348L132.615 133.149C133.373 133.635 134.252 133.891 135.152 133.891H139.185C140.762 133.891 142.237 133.1 143.113 131.781L155.543 113.044C156.891 111.013 157.207 108.459 156.398 106.159L155.01 102.22C153.225 97.1497 150.616 92.411 147.29 88.1914L144.813 85.0494C143.055 82.8219 140.687 81.1589 137.996 80.2715L131.543 85.9006L135.51 97.201C135.51 97.201 141.794 106.105 146.288 108.375C142.417 111.583 140.244 125.313 138.459 125.935C136.675 126.557 132.274 127.619 132.274 127.619C131.721 127.77 131.338 128.271 131.338 128.848C131.338 129.171 131.462 129.463 131.66 129.687"
      fill="#907A58"
    />
    <path
      d="M137.99 80.2687C136.031 89.0066 142.128 94.9043 137.99 105.172H131.537V78.1348L137.99 80.2687Z"
      fill="#D1805D"
    />
    <path
      d="M115.637 141.367C117.843 142.016 120.19 142.016 122.397 141.367"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M124.819 59.8039C125.471 59.8039 125.999 59.0202 125.999 58.0533C125.999 57.0865 125.471 56.3027 124.819 56.3027C124.168 56.3027 123.64 57.0865 123.64 58.0533C123.64 59.0202 124.168 59.8039 124.819 59.8039Z"
      fill="black"
    />
    <path
      d="M123.359 53.1552C124.964 52.9862 126.691 53.5687 127.531 55.108"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.017 199.357V219.999H94.2314C94.8153 217.352 96.5128 215.089 98.8845 213.791L102.487 211.817L103.754 199.357H119.017Z"
      fill="#4B1701"
    />
    <path
      d="M119.017 199.357V219.999H143.802C143.218 217.352 141.52 215.089 139.149 213.791L135.546 211.817L134.279 199.357H119.017Z"
      fill="#4B1701"
    />
    <path
      d="M14.6476 212.802H25.4977L25.9733 220H8.8418C8.8418 218.627 9.50695 217.338 10.6266 216.544L13.7928 214.299L14.6476 212.802Z"
      fill="#3F6655"
    />
    <path
      d="M49.3071 212.802H38.457L37.9814 220H55.1129C55.1129 218.627 54.4478 217.338 53.3281 216.544L50.1619 214.299L49.3071 212.802Z"
      fill="#3F6655"
    />
    <path
      d="M4.07708 95.2064C2.97551 99.5769 1.60006 104.279 0.612862 107.539C-0.290061 110.527 -0.190739 113.721 0.880729 116.651C2.53308 121.176 5.65117 128.13 10.9242 134.646L18.7706 111.946L13.633 94.0625L4.07407 95.2064H4.07708Z"
      fill="#EFDCBD"
    />
    <path
      d="M59.8772 95.2064C60.9787 99.5769 62.3542 104.279 63.3414 107.539C64.2443 110.527 64.145 113.721 63.0735 116.651C61.4212 121.176 58.3031 128.13 53.03 134.646L45.1836 111.946L50.3212 94.0625L59.8802 95.2064H59.8772Z"
      fill="#EFDCBD"
    />
    <path
      d="M48.1092 72.3521C50.4267 66.3126 43.8835 63.3305 45.1747 50.5149C46.7277 35.1187 40.344 25.0316 28.6873 30.3679C27.5045 30.9082 26.2645 31.3247 24.9823 31.5209C19.5046 32.36 17.0245 38.5112 18.4782 50.5149C20.0283 63.3033 13.5272 66.3126 15.8447 72.3521H48.1092Z"
      fill="#907A58"
    />
    <path
      d="M52.317 125.178C60.0129 140.529 57.6473 153.592 52.317 205.25H35.7785C35.7785 184.442 31.9772 154.911 31.9772 147.573C31.9772 154.911 28.1759 184.442 28.1759 205.25H11.6373C6.30708 153.592 3.94143 140.529 11.6373 125.178H52.317Z"
      fill="#B6CCAE"
    />
    <path
      d="M52.6786 125.686C48.8848 129.479 40.5262 131.544 32.1983 131.65C23.8704 131.544 15.6001 129.386 11.718 125.686C6.04099 120.009 10.6179 120.918 10.6179 102.032C10.6179 102.032 2.89918 99.4018 0.792969 94.8378C8.33594 80.2762 10.7937 72.7332 20.4429 71.3301C20.4429 85.3644 43.9506 85.3644 43.9506 71.3301C53.5998 72.7332 56.0576 80.2762 63.6006 94.8378C61.4943 99.3988 53.7756 102.032 53.7756 102.032C53.7756 121.221 57.7316 120.633 52.6786 125.686Z"
      fill="#3F6655"
    />
    <path
      d="M37.7291 63.1797V66.1799C37.7291 68.2112 39.1226 69.9738 41.091 70.4386L43.6522 71.0423C43.6522 85.0199 20.3057 85.0199 20.3057 71.0423L22.867 70.4386C24.8383 69.9738 26.2288 68.2112 26.2288 66.1799V63.1797H37.7291Z"
      fill="#EFDCBD"
    />
    <path
      d="M45.3679 75.1562C41.7532 87.172 22.3072 87.2022 18.6143 75.2438"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9769 65.3713C41.5057 65.3713 43.1129 53.265 43.1129 45.3571C43.1129 39.1877 38.1258 34.1895 31.9769 34.1895C25.828 34.1895 20.8408 39.1907 20.8408 45.3571C20.8408 53.265 22.448 65.3713 31.9769 65.3713Z"
      fill="#EFDCBD"
    />
    <path
      d="M27.3994 64.1934C28.6725 64.9419 30.1864 65.3705 31.9802 65.3705C33.6145 65.3705 35.0171 65.0143 36.218 64.3835"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.023 48.5377C43.023 48.5377 31.9773 47.4119 26.2798 38.3842C26.2798 38.3842 25.5304 42.4287 20.8412 45.3564L20.2393 44.4057C20.2393 38.2635 25.8073 32.6797 31.9321 32.6797V33.2833C38.0569 33.2833 43.9259 38.2635 43.9259 44.4057L43.023 48.5377Z"
      fill="#907A58"
    />
    <path
      d="M25.4976 205.253H14.6475V212.802H25.4976V205.253Z"
      fill="#EFDCBD"
    />
    <path d="M49.3072 205.253H38.457V212.802H49.3072V205.253Z" fill="#EFDCBD" />
    <path
      d="M27.5889 55.9023C29.163 58.8663 33.9304 59.1047 36.0282 55.9023"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.2613 48.2246C31.2613 51.5055 27.5894 51.8405 31.809 54.2763"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1139 50.3072C27.6143 50.3072 28.0199 49.9005 28.0199 49.3987C28.0199 48.897 27.6143 48.4902 27.1139 48.4902C26.6136 48.4902 26.208 48.897 26.208 49.3987C26.208 49.9005 26.6136 50.3072 27.1139 50.3072Z"
      fill="black"
    />
    <path
      d="M36.2184 50.3072C36.7188 50.3072 37.1244 49.9005 37.1244 49.3987C37.1244 48.897 36.7188 48.4902 36.2184 48.4902C35.7181 48.4902 35.3125 48.897 35.3125 49.3987C35.3125 49.9005 35.7181 50.3072 36.2184 50.3072Z"
      fill="black"
    />
    <path
      d="M24.8506 46.8662C26.822 44.6659 29.4073 44.6689 29.4073 44.6689"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8545 146.408L28.5971 146.921C30.8033 147.57 33.1509 147.57 35.357 146.921L37.0997 146.408"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6582 145.724C15.4482 140.653 16.7333 133.539 16.7333 133.539"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.2997 145.724C48.5098 140.653 47.2246 133.539 47.2246 133.539"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5417 95.1253C11.4785 92.3092 10.9999 89.632 11.6531 85.7988"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.4131 95.1253C52.4763 92.3092 52.9549 89.632 52.3018 85.7988"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7998 123.512C16.9586 127.043 19.8359 128.456 19.8359 128.456"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.1581 123.512C46.9994 127.043 44.1221 128.456 44.1221 128.456"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.7174 37.4855C25.0583 36.2872 24.8506 35.2852 24.8506 35.2852"
      stroke="black"
      strokeWidth="0.606105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MatchWithRDPicture;

const UploadFileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="56"
    viewBox="0 0 46 56"
    fill="none"
  >
    <rect width="46" height="56" rx="4" fill="#E2EAFD" />
    <path
      d="M8.75445 28.3219C7.6214 26.7352 5.29962 26.6339 4.03271 28.1159L-0.000469208 32.8337V52.0002C-0.000469208 54.2094 1.79039 56.0002 3.99953 56.0002H24.6337C26.2604 56.0002 27.2067 54.1617 26.2614 52.838L8.75445 28.3219Z"
      fill="#B4CAFA"
    />
    <ellipse cx="15.641" cy="12.1335" rx="4.6" ry="4.66667" fill="#E5B530" />
    <path
      d="M27.721 21.743C29.3647 19.7117 32.4831 19.7762 34.0414 21.8739L46 37.972V52.0001C46 54.2092 44.2091 56.0001 42 56.0001H4.1912C2.50898 56.0001 1.57827 54.0497 2.63646 52.742L27.721 21.743Z"
      fill="#9461BC"
    />
  </svg>
);

export default UploadFileIcon;

import { colors } from '@/constants/colors';

interface CheckWaveIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const CheckWaveIcon = ({
  width = '60',
  height = '60',
  color,
}: CheckWaveIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9262 5.67578L36.3995 10.398L44.4132 10.3832L46.8742 18.0088L53.366 22.7064L50.8755 30.322L53.366 37.9377L46.8742 42.6353L44.4132 50.2609L36.3995 50.2461L29.9262 54.9683L23.4528 50.2461L15.4391 50.2609L12.9782 42.6353L6.48633 37.9377L8.97683 30.322L6.48633 22.7064L12.9782 18.0088L15.4391 10.3832L23.4528 10.398L29.9262 5.67578Z"
      fill={color || colors.purpleFig}
      stroke={color || colors.purpleFig}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2988 30.3217L27.4604 36.4833L39.7835 24.1602"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckWaveIcon;
